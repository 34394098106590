<template lang="pug">
main
    .row.text-center
        .col.my-4
            router-link.tile(to="/profil")
                .icon: include ../assets/tiles/profile.svg
                br
                strong Profil

        .col.my-4
            router-link.tile(:to="`/${secret}`")
                .icon: include ../assets/tiles/leads.svg
                br
                strong Auftragszentrale

        .col.my-4
            router-link.tile(to="/bewertungen")
                .icon: include ../assets/tiles/ratings.svg
                br
                strong Bewertungen

        .col.my-4
            router-link.tile(to="/kontakt")
                .icon: include ../assets/tiles/contact.svg
                br
                strong Kontakt

        .col.my-4
            router-link.tile(to="/interview")
                .icon: include ../assets/tiles/interview.svg
                br
                strong Interview

        .col.my-4(v-if="companyOfTheMonth")
            router-link.tile(to="/top-betrieb")
                .icon: include ../assets/tiles/trophy.svg
                br
                strong Auszeichnung
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'companyOfTheMonth',
            'secret'
        ])
    }
}
</script>

<style lang="scss" scoped>
.tile {
    &:hover {
        .icon {
            background: #f5f5f5;
            color: #555;
            text-decoration: none;
        }
    }

    .icon {
        background-color: #f7f7f7;
        background-image: linear-gradient(0deg, #f7f7f7, #ebebeb);
        border-radius: 50%;
        box-shadow: 0 3px 8px #aaa, inset 0px 2px 3px #fff;
        color: #a7a7a7;
        display: flex;
        height: 144px;
        line-height: 144px;
        margin: auto;
        position: relative;
        text-align: center;
        width: 144px;

        &::before {
            background: #fff;
            border-radius: 50%;
            border-top: 2px solid #ddd;
            bottom: -18px;
            box-shadow: inset 0px 8px 48px #ddd;
            content: "";
            display: block;
            left: -18px;
            position: absolute;
            right: -18px;
            top: -18px;
            z-index: -1;
        }

        svg {
            margin: auto;
        }
    }
}
</style>
