<template lang="pug">
.rating
    .rating-author Von {{ author }} am {{ formatDate(rating.createdAt) }}

    .rating-stars
        .total
            rating-stars(:rating="rating.total")
            span {{ rating.total }} von 5 Sternen
        .detail
            span Qualität: {{ rating.criteria.quality }} / 5
            span Pünktlichkeit: {{ rating.criteria.punctuality }} / 5
            span Service: {{ rating.criteria.service }} / 5
            span Preis/Leistung: {{ rating.criteria.price }} / 5

    .rating-title {{ rating.title }}
    p {{ rating.body }}

    .answer-button(v-if="!(rating.answer ? rating.answer.text : null)")
        button.btn.btn-secondary(@click="answerAction") Antworten

    .rating-answer(v-if="rating.answer?.text ") {{ rating.answer.text }}

    upgrade-required-modal(:showModal="showModal", @closeUpgradeRequiredModal="showModal=false")
</template>

<script>
import { formatDate } from '@/utils/formatters'
import RatingStars from '@/components/RatingStars'
import UpgradeRequiredModal from '@/components/UpgradeRequiredModal.vue'
import api from '@/utils/api'

export default {
    components: {
        RatingStars,
        UpgradeRequiredModal
    },
    props: {
        rating: {
            type: Object,
            required: true
        },
        answersTotal: {
            type: Number,
            required: true
        }
    },
    emits: [
        'selectedRating'
    ],
    data () {
        return {
            premiumProducts: ['business', 'expert', 'premium'],
            showModal: false,
            profile: null
        }
    },
    computed: {
        author () {
            if (this.rating.author.city) {
                return `${this.rating.author.name} (${this.rating.author.city})`
            }
            return this.rating.author.name
        },
        canAnswerRatings () {
            return this.answersTotal < 2 || this.premiumProducts.includes(this.profile.product)
        }
    },
    async created () {
        this.profile = await api.get('/profile').then(res => res.data)
    },
    methods: {
        formatDate,
        answerAction () {
            if (!this.canAnswerRatings) {
                this.showModal = true
                return
            }
            this.$parent.$emit('selectedRating', this.rating)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.rating {
    border: 1px solid $gray-300;
    margin-bottom: 1.5rem;
    padding: 1rem;
}

.rating-author {
    color: $text-muted;
    font-size: $font-size-sm;
    margin-bottom: 0.5rem;
}

.rating-stars {
    font-size: $font-size-sm;
    margin-bottom: 1rem;

    .total {
        align-items: center;
        display: flex;
        margin-bottom: 0.5rem;

        span {
            line-height: 16px; // same as ratingstars svg height
            margin-left: 0.5rem;
        }
    }

    .detail {
        color: $text-muted;

        span {
            margin-right: 0.5rem;
        }
    }
}

.rating-title {
    font-weight: $font-weight-bold;
    margin-top: 1rem;
}

.answer-button {
    margin-top: 2rem;
    text-align: left;

    .btn {
        font-weight: 700;
        border-radius: 0.9375rem;
    }
}

.rating-answer {
    border-left: 2px solid $gray-500;
    font-style: italic;
    line-height: 1.5;
    margin-top: 1rem;
    max-height: calc(3 * 1.5rem); // max. 2 lines of text
    overflow: hidden;
    padding-left: .5rem;
}

</style>
