<template lang="pug">
main.text-center
    .success-icon.mx-auto.mb-4(style="width: 3rem")
        include ../../assets/icons/check.svg

    h1.h2.mb-4 Vielen Dank für Ihre Bestellung

    p.mb-5
        | Ihre Auftragsbestätigung wird Ihnen per E-Mail von unserem Kundenservice zugestellt.
        br
        | Bitte beachten Sie, dass dies nur werktags geschieht und bis zu 48 Stunden in Anspruch nehmen kann.

    router-link.btn.btn-secondary(:to="`/${secret}`") Zurück zur Anfragenübersicht
</template>

<script>
import { mapGetters } from 'vuex'
import CheckoutTab from './_Checkout'
import ProductsTab from './_Products'

export default {
    components: {
        CheckoutTab,
        ProductsTab
    },
    data () {
        return {
            selectedProduct: {},
            checkoutConfirmed: false
        }
    },
    computed: {
        ...mapGetters([
            'secret'
        ])
    }
}
</script>
