<template lang="pug">
main

    .text-center(v-if="success")
        .success-icon.mx-auto.mb-4(style="width: 3rem")
            include ../../assets/icons/check.svg

        h1.h2.mb-4 Vielen Dank

        p.mb-5
            | Ihre Angaben werden jetzt von unserem Kundenservice bearbeitet und in Ihrem Profil hinterlegt.
            br
            | Sie möchten außerdem Ihr Logo oder weitere Bilder auf Ihrem Profil veröffentlichen?
            br
            | Schicken Sie uns diese gerne per Mail an kundenservice@digitaleseiten.de

        router-link.btn.btn-secondary(:to="`/${secret}`") Zurück zur Anfragenübersicht

    form(v-else-if="form", @submit.prevent="submit")

        h1.h2.mb-4 Vervollständigen Sie jetzt Ihr Profil

        p Wir benötigen von Ihnen noch einige Informationen, um Ihren Betrieb auf unserem Portal bestmöglich zu präsentieren.
        p Bitte füllen Sie dazu das folgende Formular möglichst vollständig aus.

        hr.my-5

        legend Kontaktdaten

        .form-group
            label Firmenname
            input.form-control(placeholder="Firmenname", v-model="form.name")

        .form-group
            label Straße &amp; Hausnummer
            input.form-control(placeholder="Straße + Hausnummer", v-model="form.address.street")

        .form-row
            .form-group.col-4
                label PLZ
                input.form-control(placeholder="PLZ", v-model="form.address.zipcode")
            .form-group.col-8
                label Stadt
                input.form-control(placeholder="Stadt", v-model="form.address.city")

        .form-group
            label Telefonnummer
            input.form-control(placeholder="Telefonnummer", v-model="form.contact.phone")
            small.form-text Bitte beachten Sie, dass wir auf Ihrem Profil eine sogenannte Tracking-Nummer einsetzen, um Ihnen transparent aufzeigen zu können, wie viele Anrufe Sie über Ihr Profil erhalten. Ihre Kunden erreichen Sie selbstverständlich wie gehabt. Sprechen Sie uns gerne an, wenn Sie Fragen dazu haben.

        .form-group
            label E-Mail-Adresse für Kundenanfragen
            input.form-control(placeholder="E-Mail-Adresse", v-model="form.contact.email")

        hr.my-5

        legend Angaben zu Ihrem Betrieb

        .form-group
            label Webseite Ihres Betriebs
            input.form-control(placeholder="Webseite", v-model="form.contact.website")

        .form-group
            label Ihre Öffnungszeiten
            input.form-control(placeholder="Öffnungszeiten", v-model="form.openingHours")

        .form-group
            label Rechtsform (bspw. GmbH, Einzelunternehmen, ...)
            input.form-control(placeholder="Rechtsform", v-model="form.legalForm")

        .form-group
            label.d-block Ist Ihr Betrieb ein Meisterbetrieb?
            .form-check.form-check-inline
                input#isMeisterbetriebTrue.form-check-input(
                    type="radio",
                    v-model="form.attributes.isMeisterbetrieb",
                    :value="true"
                )
                label.form-check-label(for="isMeisterbetriebTrue") Ja
            .form-check.form-check-inline
                input#isMeisterbetriebFalse.form-check-input(
                    type="radio",
                    v-model="form.attributes.isMeisterbetrieb",
                    :value="false"
                )
                label.form-check-label(for="isMeisterbetriebFalse") Nein
        .form-group
            label.d-block Ist Ihr Betrieb ein Ausbildungsbetrieb?
            .form-check.form-check-inline
                input#isTrainingCompanyTrue.form-check-input(
                    type="radio",
                    v-model="form.attributes.isTrainingCompany",
                    :value="true"
                )
                label.form-check-label(for="isTrainingCompanyTrue") Ja
            .form-check.form-check-inline
                input#isTrainingCompanyFalse.form-check-input(
                    type="radio",
                    v-model="form.attributes.isTrainingCompany",
                    :value="false"
                )
                label.form-check-label(for="isTrainingCompanyFalse") Nein

        .form-group
            label Anzahl Mitarbeiter
            input.form-control(placeholder="Anzahl Mitarbeiter", v-model="form.attributes.numEmployees")

        .form-group
            label Beschreiben Sie Ihren Betrieb in 2-3 Sätzen (bspw. spezielle Leistungen, Geschichte, Referenzen, etc.)
            textarea.form-control(v-model="form.description")

        hr.my-5

        legend Daten zu Ihren Kundenanfragen

        .form-group
            label Aus welchem Umkreis möchten Sie Anfragen erhalten? (Angabe in Kilometer)
            input.form-control(placeholder="Umkreis", v-model="form.leadRadius")

        .form-group
            label.d-block Welche Dienstleistungen bietet Ihr Betrieb an?
            template(v-for="tag of vertical.tags")
                .form-check.form-check-inline
                    input.form-check-input(
                        :id="`cbProfileTags_${tag.id}`"
                        type="checkbox",
                        v-model="form.tags",
                        :value="tag.id"
                    )
                    label.form-check-label(:for="`cbProfileTags_${tag.id}`") {{ tag.name }}

        .form-group
            label Möchten Sie Anfragen auch per SMS erhalten, tragen Sie bitte hier Ihre Mobilnummer ein:
            input.form-control(placeholder="Mobilnummer", v-model="form.leadMobile")

        .mt-5
            button.btn.btn-primary.btn-lg Formular absenden

</template>

<script>
import api from '@/utils/api'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            form: null,
            profile: null,
            vertical: null,
            success: false
        }
    },
    computed: {
        ...mapGetters([
            'secret'
        ])
    },
    async created () {
        this.profile = await api.get('/profile').then(res => res.data)
        this.vertical = await api.get('/profile/vertical').then(res => res.data)

        this.form = {
            name: this.profile.name,
            address: {
                street: this.profile.address.street,
                zipcode: this.profile.address.zipcode,
                city: this.profile.address.city
            },
            contact: {
                phone: this.profile.contact.phone,
                email: this.profile.contact.email,
                website: this.profile.contact.website
            },
            openingHours: '',
            legalForm: '',
            attributes: {
                isMeisterbetrieb: this.profile.attributes.isMeisterbetrieb,
                isTrainingCompany: this.profile.attributes.isTrainingCompany,
                numEmployees: this.profile.attributes.numEmployees
            },
            description: '',
            leadRadius: '',
            tags: this.profile.tags || [],
            leadMobile: ''
        }
    },
    methods: {
        async submit () {
            try {
                await api.post('/profile/checklist', this.form).then(res => res.data)
                this.success = true
            } catch (err) {
                console.error(err)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

nav {
    @include media-breakpoint-down(md) {
        flex-direction: column;

        .btn {
            margin-bottom: 0.4rem;
            width: 100% !important;
        }
    }
}
</style>
