<template lang="pug">

.modal-backdrop.fade.show(v-if="showModal")
.modal(:class="{ 'd-block': showModal }", tabindex="-1", role="dialog")
    .modal-dialog: .modal-content
        .modal-header
            strong.modal-title Upgrade erforderlich
            button.close(type="button", @click="closeUpgradeRequiredModal")
                span(aria-hidden="true") &times;
        .modal-body.text-center
            figure.mb-4
                include ../assets/icons/lock.svg

            h2 Upgrade erforderlich

            p Erhalten Sie jetzt Zugriff auf mehr Funktionen unseres Kundenlogin indem Sie Ihre Mitgliedschaft Upgraden:
            router-link.btn.btn-primary.mb-4(:to="`/${secret}/upgrade`") Jetzt Upgrade buchen!

            p
                span.text-success.mr-2 ✓
                | Annahmen weiterer Aufträge aus Ihrer Region
                br
                span.text-success.mr-2 ✓
                | Weitere Funktionalitäten im Kunden Login Bereich
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        showModal: {
            type: Boolean,
            required: true
        }
    },
    emits: ['closeUpgradeRequiredModal'],
    computed: {
        ...mapGetters([
            'secret'
        ])
    },
    methods: {
        closeUpgradeRequiredModal () {
            this.$emit('closeUpgradeRequiredModal')
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
