<template lang="pug">
main(v-if="isLoading")
    .d-flex.align-items-center.flex-column.my-5
        .spinner-border.text-primary
        .mt-3 Lade Kundenanfrage...

main(v-else)
    .company-of-the-month.mb-5.d-flex(v-if="companyOfTheMonth")
        .text
            .h3.mb-3 Herzlichen Glückwunsch
            p Sie wurden als unser Top Betrieb des Monats {{ formatMonth(companyOfTheMonth) }} ausgezeichnet! Erfahren Sie nachfolgend was das für Sie bedeutet und erhalten Sie Ihre Auszeichnung in digitaler Form für Ihre Homepage!
            router-link.btn.btn-primary(to="/top-betrieb") Auszeichnung erhalten
        img.ml-3(:src="companyOfTheMonthImage" height="150")

    p.small: router-link(to="/") &laquo; Zurück zur Übersicht
    h1.h3.mb-3 Ihre offenen Anfragen

    .assignment-list.mb-5(v-if="openAssignments.length")
        .assignment.row(v-for="assignment in openAssignments", @click="$router.push(`/${secret}/${assignment.id}`)")
            .col-sm-7 Vom {{ formatDateTime(assignment.createdAt) }} Uhr
            .col-sm-5.text-sm-right.order-1.order-sm-0
                router-link(:to="`/${secret}/${assignment.id}`") Anfrage öffnen &raquo;
            .col-12
                | aus {{ assignment.lead.address.zipcode }} {{ assignment.lead.address.city }} zum
                |
                strong Thema: {{ assignment.lead.service.map((s) => s.name).join(', ') }}
    .assignment-list.mb-5(v-else)
        .assignment.row
            .col
                p: em Sie haben derzeit keine offenen Anfragen
                router-link.font-italic(:to="`/${secret}/upgrade`") Erhöhen Sie Ihr Einzugsgebiet für Kundenanfragen und erhalten Sie so mehr Anfragen &raquo;

    partner-banner

    template(v-if="closedAssignments.length")
        h2.h3.mt-5.mb-3 Ihre bisherigen Anfragen

        .assignment-list
            .assignment.row(
                v-for="assignment in closedAssignments",
                @click="$router.push(`/${secret}/${assignment.id}`)"
            )
                .col-sm-7 Vom {{ formatDateTime(assignment.createdAt) }} Uhr
                .col-sm-5.text-sm-right
                    .text-success(v-if="assignment.status === 'accepted'") Angenommen
                    .text-danger(v-if="assignment.status === 'declined'") Abgelehnt
                    .text-muted(v-if="assignment.status === 'expired'") Frist abgelaufen
                .col-12
                    | aus {{ assignment.lead.address.zipcode }} {{ assignment.lead.address.city }} zum
                    |
                    strong Thema: {{ assignment.lead.service.map((s) => s.name).join(', ') }}
</template>

<script>
import api from '@/utils/api'
import PartnerBanner from './_PartnerBanner.vue'
import { mapGetters } from 'vuex'
import { formatDateTime, formatMonth } from '@/utils/formatters'

export default {
    components: {
        PartnerBanner
    },
    data () {
        return {
            isLoading: true,
            openAssignments: [],
            closedAssignments: []
        }
    },
    computed: {
        ...mapGetters([
            'secret',
            'companyOfTheMonth',
            'vertical'
        ]),
        companyOfTheMonthImage () {
            return `${this.vertical.url}/img/illustrations/companyofthemonth-${this.companyOfTheMonth}.svg`
        }
    },
    async created () {
        this.loadAssignments()
    },
    methods: {
        formatDateTime,
        formatMonth,
        async loadAssignments () {
            this.isLoading = true

            const assignments = await api.get('/assignments').then(res => res.data.assignments)
            this.openAssignments = assignments.filter(a => a.status === 'open')
            this.closedAssignments = assignments.filter(a => a.status !== 'open')

            this.isLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.assignment-list {
    .assignment {
        border-bottom: 1px solid $gray-300;
        padding-bottom: $spacer;
        padding-top: $spacer;

        &:first-child {
            border-top: 1px solid $gray-300;
        }

        &:hover {
            background-color: $gray-100;
        }
    }
}

.assignment-media {
    @include media-breakpoint-only(sm) {
        column-count: 2;
    }
    @include media-breakpoint-only(md) {
        column-count: 2;
    }
}
</style>
