<template lang="pug">
main
    p.small: router-link(to="/") &laquo; Zurück zur Übersicht
    h3.mb-3 Bewertung beantworten

    .answer-confirmation.mt-5
        h3.text-center Sind Sie zufrieden mit Ihrer Antwort?
        h6.text-center Bitte überprüfen und bestätigen Sie Ihre Antwort.

        .row.mt-5
            .col-9.pr-0
                .answer-box
                    p {{ answer.body.text }}

                    .row.mt-5
                        .col-8.mp-1.d-flex.justify-content-end.align-items-center
                            span.link-text(href='#' @click.prevent="moveToCreate") Zurück zur Bewertung
                        .col-4.text-left
                            button.btn.btn-primary(@click="saveAnswer") Antwort senden
            .col-3.image-box
                figure
                    img.img-fluid(src="@/assets/illustrations/my-answer.svg")
</template>

<script>

import api from '@/utils/api'

export default {
    props: {
        answer: {
            type: Object,
            required: true
        }
    },
    emits: ['success', 'failed', 'moveToCreate', 'answerConfirmed'],
    methods: {
        async saveAnswer () {
            this.$emit('answerConfirmed')

            try {
                await api.patch(`/ratings/${this.answer.rating}`, { answer: this.answer.body }).then(res => res.data)
                this.$emit('success')
            } catch (err) {
                this.$emit('failed')
            }
        },
        moveToCreate () {
            this.$emit('moveToCreate')
        }
    }
}
</script>

<style lang="scss" scoped>

.answer-confirmation {
    width: 93%;
    margin: auto;
    position: relative;

    .btn {
        display: block;
        font-weight: 700;
        border-radius: 0.9375rem;
    }

    a {
        color: rgba(0, 0, 0, 0.50);
        text-decoration: underline;
    }

    .answer-box {
        padding: 2rem;
        border-radius: 2rem;
        background: #FBFBFB;
    }

    .image-box {
        padding-left: 0;
        padding-right: 0;
        margin-top: 2rem;
    }

    .link-text {
        color: rgba(0, 0, 0, 0.5);
        text-decoration: underline;
        cursor: pointer;
    }
}

</style>
