<template lang="pug">
ListTab(v-if="currentTab === 'list'",  @selectedRating="selectedRating = $event")
CreateTab(v-if="currentTab === 'create'", :rating="selectedRating", :answer="answer", @answer="setAnswer($event)")
OverviewTab(v-if="currentTab === 'overview'", :answer="answer", @success="successTab = true", @failed="errorTab = true", @answerConfirmed="answerConfirmed = true" @moveToCreate="answerDecline = true" )
SuccessTab(v-if="currentTab === 'success'", :rating="selectedRating", @moveToList="moveToList")
ErrorTab(v-if="currentTab === 'error'", @moveToList="moveToList")
</template>

<script>
import { mapGetters } from 'vuex'
import ListTab from '@/views/Ratings/Tabs/_List.vue'
import CreateTab from '@/views/Ratings/Tabs/_Create.vue'
import OverviewTab from '@/views/Ratings/Tabs/_Overview.vue'
import SuccessTab from '@/views/Ratings/Tabs/_Success.vue'
import ErrorTab from '@/views/Ratings/Tabs/_Error.vue'

export default {
    components: {
        CreateTab,
        OverviewTab,
        SuccessTab,
        ErrorTab,
        ListTab
    },
    data () {
        return {
            selectedRating: null,
            answer: null,
            successTab: null,
            errorTab: null,
            answerConfirmed: false,
            answerDecline: false
        }
    },
    computed: {
        ...mapGetters([
            'secret'
        ]),
        currentTab () {
            if (this.errorTab && this.answerConfirmed) {
                return 'error'
            }

            if (this.successTab && this.answerConfirmed) {
                return 'success'
            }

            if (this.answer && !this.answerDecline) {
                return 'overview'
            }

            if (this.selectedRating) {
                return 'create'
            }

            return 'list'
        }
    },
    methods: {
        moveToList () {
            this.errorTab = null
            this.successTab = null
            this.answer = null
            this.selectedRating = null
        },
        setAnswer (event) {
            this.answer = event
            this.answerDecline = false
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
