import AssignmentsList from '@/views/Assignments/List.vue'
import AssignmentsShow from '@/views/Assignments/Show.vue'
import AssignmentsUnsubscribe from '@/views/Assignments/Unsubscribe.vue'
import CompanyOfTheMonth from '@/views/CompanyOfTheMonth.vue'
import ContactForm from '@/views/ContactForm.vue'
import Dashboard from '@/views/Dashboard.vue'
import InterviewForm from '@/views/InterviewForm.vue'
import LoginRegister from '@/views/Login/Register.vue'
import LoginReset from '@/views/Login/Reset.vue'
import LoginShow from '@/views/Login/Show.vue'
import LoginVerify from '@/views/Login/Verify.vue'
import PartnersList from '@/views/Partners/List.vue'
import PartnersDesignenlassen from '@/views/Partners/Designenlassen.vue'
import PartnersFirstDebit from '@/views/Partners/FirstDebit.vue'
import PartnersLuftverbundrechner from '@/views/Partners/Luftverbundrechner.vue'
import PartnersMeinDachdecker from '@/views/Partners/MeinDachdecker.vue'
import PartnersMeinMaurer from '@/views/Partners/MeinMaurer.vue'
import PartnersMeinSHK from '@/views/Partners/MeinSHK.vue'
import PartnersMeinZimmerer from '@/views/Partners/MeinZimmerer.vue'
import ProfileCancellationConfirmation from '@/views/Profile/CancellationConfirmation.vue'
import ProfileChecklist from '@/views/Profile/Checklist.vue'
import ProfileEdit from '@/views/Profile/Edit.vue'
import RatingsShow from '@/views/Ratings/Show.vue'
import RatingsQRCode from '@/views/Ratings/QRCode.vue'
import UpgradeShow from '@/views/Upgrade/Show.vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from './store'
import api from './utils/api'

const routes = [
    {
        path: '/',
        component: Dashboard,
        meta: { requiresLogin: true }
    },
    {
        path: '/top-betrieb',
        component: CompanyOfTheMonth
    },
    {
        path: '/kontakt',
        component: ContactForm,
        meta: { requiresLogin: true }
    },
    {
        path: '/interview',
        component: InterviewForm,
        meta: { requiresLogin: true }
    },
    {
        path: '/profil',
        component: ProfileEdit,
        meta: { requiresLogin: true }
    },
    {
        path: '/bewertungen',
        component: RatingsShow,
        meta: { requiresLogin: true }
    },
    {
        path: '/bewertungen/qrcode',
        component: RatingsQRCode,
        meta: { requiresLogin: true }
    },
    {
        path: '/einloggen',
        component: LoginShow
    },
    {
        path: '/passwort-vergessen',
        component: LoginReset
    },
    {
        path: '/registrieren',
        component: LoginRegister
    },
    {
        path: '/freischalten',
        component: LoginVerify
    },
    {
        path: '/:secret',
        component: AssignmentsList
    },
    {
        path: '/:secret/abmelden',
        component: AssignmentsUnsubscribe
    },
    {
        path: '/:secret/checkliste',
        component: ProfileChecklist
    },
    {
        path: '/:secret/kuendigungsbestaetigung',
        component: ProfileCancellationConfirmation
    },
    {
        path: '/:secret/partner',
        component: PartnersList
    },
    {
        path: '/:secret/partner/designenlassen',
        component: PartnersDesignenlassen
    },
    {
        path: '/:secret/partner/firstdebit',
        component: PartnersFirstDebit
    },
    {
        path: '/:secret/partner/luftverbundrechner',
        component: PartnersLuftverbundrechner
    },
    {
        path: '/:secret/partner/meindachdecker',
        component: PartnersMeinDachdecker
    },
    {
        path: '/:secret/partner/meinmaurer',
        component: PartnersMeinMaurer
    },
    {
        path: '/:secret/partner/meinshk',
        component: PartnersMeinSHK
    },
    {
        path: '/:secret/partner/meinzimmerer',
        component: PartnersMeinZimmerer
    },
    {
        path: '/:secret/registrieren',
        component: LoginRegister
    },
    {
        path: '/:secret/upgrade',
        component: UpgradeShow
    },
    {
        path: '/:secret/:assignment',
        component: AssignmentsShow
    }
]

const router = createRouter({
    history: createWebHistory('/zentrale'),
    routes
})

router.beforeEach(async (to, from, next) => {
    const publicRoutes = ['/einloggen', '/passwort-vergessen', '/freischalten']

    // If we're trying to access a non-public route without being Authenticated
    if (!publicRoutes.includes(to.path) && !store.getters.token) {
        // ... but URL contains a secret? Try to authenticate
        if (to.params.secret) {
            try {
                const result = await api.post('/token', { secret: to.params.secret }).then(res => res.data)
                store.dispatch('login', result)

                // also update the secret, so we can build urls
                store.dispatch('changeSecret', { secret: to.params.secret })

                return next()
            } catch {
                // Failed to Authenticate - will be redirected to Login below
            }
        }

        return next('/einloggen')
    }

    // If there is a secret in the URL and it is different from Local Secret
    if (store.getters.secret && to.params.secret && store.getters.secret !== to.params.secret) {
        // Re-Login
        const result = await api.post('/token', { secret: to.params.secret }).then(res => res.data)
        store.dispatch('login', result)
        store.dispatch('changeLoggedin', { loggedin: result.loggedin })
        store.dispatch('changeSecret', { secret: to.params.secret })
    }

    // Some Routes require to not only be authenticated, but also logged in
    if (to.meta.requiresLogin && !store.getters.loggedin) {
        return next('/einloggen')
    }

    return next()
})

export default router
