<template lang="pug">
.d-flex.align-items-center.flex-column.my-5(v-if="isLoading")
    .spinner-border.text-primary
    .mt-3 Lade ...

.row(v-else)
    .col-lg-4.mb-4
        legend Ihr Firmenlogo

        figure.mb-4
            img.img-fluid(v-if="profile.logo.path", :src="getLogoUrl()")

        button.btn.btn-block.btn-secondary(@click="$refs.logoInput.click()") Neues Logo hochladen
        input.d-none(type="file", ref="logoInput", accept="image/*", @change="upload('logo', $event.target.files[0])")

    .col-lg-8
        legend Ihre Bildergalerie

        .row.my-4
            .col-6.col-md-4.mb-4(v-for="(image, idx) in gallery")
                .card.h-100
                    img.card-img-top(v-if="image.cloudinaryId", :src="getMediaUrl(image.cloudinaryId)")
                    img.card-img-top(v-else, :src="image.path")
                    .card-body.d-flex.justify-content-between.align-items-center
                        .text-muted Bild {{ idx + 1 }} / {{ gallery.length }}
                        .delete(@click="removeImage(image.path)")
                            include ../../assets/icons/trash.svg

        p.text-center(v-if="freeImageSlots <= 0")
            | Sie können keine Bilder mehr hochladen - Löschen Sie Bilder aus Ihrer Galerie oder Upgraden Sie Ihr Paket
        p.text-center(v-else)
            p(v-if="freeImageSlots !== Infinity") Sie können noch bis zu {{ freeImageSlots }} Bilder hochladen

            button.btn.btn-secondary(@click="$refs.imageInput.click()") Neues Bild hochladen
                input.d-none(
                    type="file",
                    ref="imageInput",
                    accept="image/*",
                    @change="upload('image', $event.target.files[0])"
                )
</template>

<script>
import api from '@/utils/api'
import { Cloudinary } from 'cloudinary-core'
import { mapGetters } from 'vuex'

const cloudinary = new Cloudinary({
    cloud_name: 'hxmj4muxr',
    secure: true
})

export default {
    data () {
        return {
            newLogo: null,
            newImage: null,
            isLoading: true,
            success: false,
            profile: null
        }
    },
    computed: {
        ...mapGetters([
            'vertical'
        ]),
        gallery () {
            return this.profile.media.filter(media => media.type === 'image')
        },
        freeImageSlots () {
            if (this.profile.product === 'expert') {
                return 10 - this.gallery.length
            }

            if (this.profile.product === 'aktionskunde' || this.profile.product === 'business') {
                return 3 - this.gallery.length
            }

            return Infinity
        }
    },
    async created () {
        this.loadProfile()
    },
    methods: {
        cloudinary,
        async loadProfile () {
            this.isLoading = true
            this.profile = await api.get('/profile').then(res => res.data)
            this.isLoading = false
        },
        async updateProfile () {
            await api.post('/profile', this.profile)
            this.success = true
            // Reload Profile Data
            this.loadProfile()
        },
        getLogoUrl () {
            if (!this.profile.logo.path) {
                return
            }

            return cloudinary.url(this.profile.logo.path, { width: '500', fetchFormat: 'auto', crop: 'scale' })
        },
        getMediaUrl (cloudinaryId) {
            return cloudinary.url(cloudinaryId, { width: 500, height: 500, fetchFormat: 'auto', crop: 'fill' })
        },
        async upload (type, image) {
            this.isLoading = true

            const data = new FormData()
            data.append(type, image)

            try {
                await api.post('/profile/upload', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            } catch (error) {
                // ...
            }

            this.loadProfile()
        },
        async removeImage (path) {
            const media = this.profile.media.filter(media => media.path !== path)

            await api.post('/profile', { media })

            this.loadProfile()
        }
    }
}
</script>

<style lang="scss" scoped>
.delete {
    cursor: pointer;
    line-height: 1;

    svg {
        height: 1rem;
        width: 1rem;
    }
}
</style>
