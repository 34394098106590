<template lang="pug">
.d-flex.align-items-center.flex-column.my-5(v-if="isLoading")
    .spinner-border.text-primary
    .mt-3 Lade Profildaten...

main(v-else)
    p.small: router-link(to="/bewertungen") &laquo; Zurück zu Ihren Bewertungen
    h1.h3.my-4 Bewertungen per QR-Code

    .code
        h4 Ihr persönlicher QR-Code
        .text-center.my-5
            qrcode(ref="canvas", :value="ratingPageLink", margin=4, size=400, level="M")
            .mt-3: button.btn.btn-secondary(ref="download", @click="downloadCode") QR-Code herunterladen

    .guide
        h4 So funktioniert's in 3 Schritten

        .row.row-cols-1.row-cols-md-3
            .col
                .number 1
                p Laden Sie sich Ihren persönlichen QR-Code herunter.
            .col
                .number 2
                p Zeigen Sie Ihren Kunden diesen QR-Code zum Scannen.
            .col
                .number 3
                p Ihr Kunde gelangt über den QR-Code direkt zu Ihrem Profil und kann seine Bewertung dort abgeben.
</template>

<script>
import api from '@/utils/api'
import Qrcode from 'qrcode.vue'

export default {
    components: {
        Qrcode
    },
    data () {
        return {
            isLoading: true,
            profile: null
        }
    },
    computed: {
        ratingPageLink () {
            return `${this.$store.state.vertical.url}${this.profile.url}/bewerten`
        }
    },
    async created () {
        this.profile = await api.get('/profile').then(res => res.data)
        this.isLoading = false
    },
    methods: {
        downloadCode () {
            const canvas = this.$refs.canvas.$el

            const link = document.createElement('a')
            link.download = 'qrcode.png'
            link.href = canvas.toDataURL()
            link.click()

            // button.setAttribute('download', canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
            // window.location.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.code {
    background-color: $primary;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2rem;

    @include media-breakpoint-down(md) {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    h4 {
        color: $white;
    }

    canvas {
        border-radius: 0.5rem;
        max-width: 100%;
        height: auto !important;
    }
}

.guide {
    margin-top: 3rem;

    h2 {
        margin-bottom: 2rem;
    }

    .number {
        font-size: 5rem;
        font-weight: bold;
        text-align: center;
    }

    p {
        max-width: 80%;
        margin: auto;
        text-align: center;
    }
}
</style>
