<template lang="pug">
main
    p.small: router-link(:to="`/${secret}`") &laquo; Zurück zur Anfragenübersicht

    .text-center(v-if="success")
        .success-icon.mx-auto.mb-4(style="width: 3rem")
            include ../../assets/icons/check.svg

        h1.h2.mb-4 Fast geschafft!

        p.mb-5
            | Bitte bestätigen Sie Ihre Zugangsdaten, über einen Link, den wir Ihnen per Mail gesendet haben.
            br
            | Falls Sie die Mail nicht in Ihrem Posteingang sehen, prüfen Sie bitte auch Ihren Spam Ordner

    form(v-else, @submit.prevent="register")
        h1.h2.mb-2 Zugangsdaten festlegen
        p.mb-4 Willkommen in Ihrem persönlichen Bereich von {{ vertical.name }}. Um diesen nutzen zu können, legen Sie jetzt bitte einmalig Ihre Zugangsdaten fest.
        .row
            .col-md-6
                .form-group
                    label E-Mail
                    input.form-control(type="email", required, v-model="username")
                .form-group
                    label Passwort
                    input.form-control(type="password", required, v-model="password")
                    small.form-text.text-danger(v-if="error") Registrierung fehlgeschlagen. Bitte prüfen Sie Ihre Angaben oder wenden Sie sich an unseren Kundenservice
            .col-md-6
                .font-weight-bold Tipps für ein sicheres Passwort:
                .password-tip.d-flex.align-items-center(:class="{ completed: hasCompletedLength }")
                    include ../../assets/icons/check.svg
                    span Mindestens 8 Zeichen
                .password-tip.d-flex.align-items-center(:class="{ completed: hasBothCaseLetters }")
                    include ../../assets/icons/check.svg
                    span Groß- und Kleinbuchstaben
                .password-tip.d-flex.align-items-center(:class="{ completed: hasSpecialCharacter }")
                    include ../../assets/icons/check.svg
                    span Mindestens 1 Sonderzeichen
            .col-md-12
                .form-check.form-check-inline
                    input#cbNewsletter.form-check-input(type="checkbox", v-model="newsletter")
                    label.form-check-label(for="cbNewsletter") Ja, ich möchte den Newsletter mit Informationen zu Produkten, Gewinnspielen und Aktionen erhalten.
                .form-check.form-check-inline
                    input#cbToS.form-check-input(type="checkbox", required, v-model="acceptToS")
                    label.form-check-label(for="cbToS")
                        | Ich akzeptiere die
                        |
                        a(href="/agb", target="_blank") AGB
                        |
                        | und habe die Datenschutzerklärung zur Kenntnis genommen.
                    small.form-text.text-danger(v-if="errorToS") Registrierung fehlgeschlagen. Bitte prüfen Sie Ihre Angaben
                .form-group.mt-4
                    button.btn.btn-secondary.px-4(type="submit") Zugangsdaten anlegen
</template>

<script>
import api from '@/utils/api'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            error: null,
            errorToS: false,
            username: '',
            password: '',
            newsletter: false,
            acceptToS: false,
            success: false
        }
    },
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ]),
        hasCompletedLength () {
            return this.password.length >= 8
        },
        hasBothCaseLetters () {
            const hasUppercase = /[A-Z]/.test(this.password)
            const hasLowercase = /[a-z]/.test(this.password)

            return hasUppercase && hasLowercase
        },
        hasSpecialCharacter () {
            return /[^a-zA-Z0-9]+/.test(this.password)
        }
    },
    async created () {
        const profile = await api.get('/profile').then(res => res.data)
        this.username = profile.contact.email
    },
    methods: {
        async register () {
            this.error = null

            if (!this.acceptToS) {
                this.errorToS = true
                return
            }

            const { username, password, newsletter } = this
            try {
                await api.post('/profile/credentials', { username, password, newsletter }).then(res => res.data)
                this.success = true
            } catch (err) {
                this.error = err
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.password-tip {
    margin-top: 1rem;

    svg {
        fill: $gray-400;
        height: 1rem;
        margin-right: 0.4rem;
        width: 1rem;
    }

    &.completed {
        svg {
            fill: $success;
        }
    }
}
</style>
