<template lang="pug">
main
    p.small: .breadcrumb-link(@click="moveToList") &laquo; Zurück zu den Bewertungen
    h3.mb-3 Bewertung beantworten

    .answer-success
        .success-icon.mx-auto.mb-4(style="width: 3rem")
            include ../../../assets/icons/check.svg
        h3.mt-3.text-center Vielen Dank!
        h6.text-center.mb-4 Sie haben soeben auf Ihre Bewertung von {{ author }} geantwortet.

        button.btn.btn-primary(@click="redirectToProfile").m-auto Antwort ansehen

</template>

<script>

import { mapGetters } from 'vuex'

export default {
    props: {
        rating: {
            type: Object,
            required: true
        }
    },
    emits: ['moveToList'],
    computed: {
        ...mapGetters([
            'profileLink'
        ]),
        author () {
            if (this.rating.author.city) {
                return `${this.rating.author.name} (${this.rating.author.city})`
            }
            return this.rating.author.name
        }
    },
    methods: {
        redirectToProfile () {
            window.location.href = this.profileLink
        },
        moveToList () {
            this.$emit('moveToList')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_breadcrumb";

.answer-success {
    width: 70%;
    margin: auto;

    .btn {
        display: block;
        font-weight: 700;
        border-radius: 0.9375rem;
    }
}

</style>
