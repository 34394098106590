<template lang="pug">
.map(ref="map")
</template>

<script>
import StartMarker from '@/assets/icons/marker-start.svg'
import EndMarker from '@/assets/icons/marker-end.svg'

export default {
    props: {
        destination: {
            type: Array,
            required: true,
            default () {
                return [0, 0]
            }
        },
        origin: {
            type: Array,
            required: false,
            default () {
                return [0, 0]
            }
        },
        showRoute: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'distance'
    ],
    data () {
        return {
            map: null
        }
    },
    async created () {
        await new Promise(resolve => {
            window.GoogleMapsInit = resolve

            // TODO: check, if script is already appended to body

            const script = document.createElement('script')
            script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyCC6bhpNU8gNuoyDh2-ZCe7rxIMpM68H8Q&language=de&region=DE&callback=GoogleMapsInit')

            document.body.appendChild(script)
        })

        this.initMap()
    },
    methods: {
        initMap () {
            const google = window.google

            const destination = new google.maps.LatLng(this.destination[1], this.destination[0])

            const options = {
                zoom: 10,
                center: destination,
                disableDefaultUI: true,
                gestureHandling: 'none',
                mapTypeControl: false,
                zoomControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [{ stylers: [{ saturation: -100 }] }, { featureType: 'water', elementType: 'geometry.fill', stylers: [{ color: '#0099dd' }] }, { elementType: 'labels', stylers: [{ visibility: 'on' }] }, { featureType: 'poi.park', elementType: 'geometry.fill', stylers: [{ color: '#1ABB9C' }] }, { featureType: 'road.highway', elementType: 'labels', stylers: [{ visibility: 'on' }] }, { featureType: 'road.arterial', elementType: 'labels.text', stylers: [{ visibility: 'on' }] }, { featureType: 'road.local', elementType: 'labels.text', stylers: [{ visibility: 'on' }] }, {}]
            }

            this.map = new google.maps.Map(this.$refs.map, options)

            this.addMarker(destination, EndMarker)

            // Show Route if Origin is given
            if (this.origin[0] !== 0 && this.origin[1] !== 0) {
                this.addRouteToOrigin()
            }
        },
        addMarker (position, icon) {
            new window.google.maps.Marker({ // eslint-disable-line no-new
                position,
                map: this.map,
                icon: icon
            })
        },
        async addRouteToOrigin () {
            const directionsDisplay = new window.google.maps.DirectionsRenderer()
            const directionsService = new window.google.maps.DirectionsService()

            directionsDisplay.setMap(this.map)

            const request = {
                origin: new window.google.maps.LatLng(this.origin[1], this.origin[0]),
                destination: new window.google.maps.LatLng(this.destination[1], this.destination[0]),
                travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
                unitSystem: window.google.maps.UnitSystem.METRIC
            }

            const directions = await new Promise((resolve, reject) => {
                directionsService.route(request, function (response, status) {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        return resolve(response)
                    } else {
                        return reject(new Error('DirectionsService returned non-ok response'))
                    }
                })
            })

            new window.google.maps.DirectionsRenderer({ // eslint-disable-line no-new
                map: this.map,
                directions,
                suppressMarkers: true
            })

            const leg = directions.routes[0].legs[0]

            new window.google.maps.Marker({ // eslint-disable-line no-new
                position: leg.start_location,
                map: this.map,
                icon: StartMarker
            })

            this.$emit('distance', leg.distance.text)
        }
    }
}
</script>
