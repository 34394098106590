<template lang="pug">
main(v-if="profile")
    p.small: button.btn.btn-sm.btn-link.p-0(@click="$emit('clearSelectedProduct')") &laquo; Zurück zur Produktübersicht

    h1.h2.mb-5.text-center Bestellung abschließen

    form.checkout.form(@submit.prevent="performCheckout")
        input(type="hidden", name="product_name", v-model="selectedProduct.name")
        input(type="hidden", name="product_price", v-model="selectedProduct.price")

        .row.align-content-stretch.flex-wrap
            .col-lg-6.mb-4
                .card.card-body.h-100
                    h3.mb-4 Ihr ausgewähltes Paket
                    .media
                        .d-none.d-sm-block.product-icon.mr-3.mt-2(style="width: 2rem")
                            include ../../assets/icons/check.svg
                        .media-body
                            strong {{ selectedProduct.name }} - Laufzeit 1 Jahr
                            ul.small.pl-3
                                template(v-if="selectedProduct.id === 'business'")
                                    li 10 Annahmen von Kundenanfragen inkl. Freischaltung Kundeninfo
                                    li Individueller Umkreis für Kundenanfragen 0-50 km
                                    li Ausführliches Unternehmensprofil mit 3 Bildern
                                template(v-if="selectedProduct.id === 'expert'")
                                    li 25 Annahmen von Kundenanfragen inkl. Freischaltung Kundeninfo
                                    li Individueller Umkreis für Kundenanfragen 0-100 km
                                    li Ausführliches Unternehmensprofil mit bis zu 25 Bildern
                                template(v-if="selectedProduct.id === 'premium'")
                                    li Unbegrenzte Annahme von Kundenanfragen inkl. Freischaltung Kundeninfo
                                    li Bundesweite Kundenanfragen oder wählbarer Umkreis von 0-200km
                                    li Ausführliches Unternehmensprofil mit bis zu 25 Bildern
                            strong {{ selectedProduct.price }}€ pro Monat
                            small.ml-2 zzgl. {{ taxRate }}% MwSt.
            .col-lg-6.mb-4
                .card.card-body.h-100
                    h3.mb-4 Wählen Sie Ihre Zahlungsart
                    .form-group
                        .form-check
                            input#payment_sepa.form-check-input(type="radio", v-model="paymentMethod", value="sepa")
                            label.form-check-label(for="payment_sepa") Monatlich per Lastschrift
                            small.form-text monatlicher Abbuchungsbetrag: {{ formatCurrency(selectedProduct.price * (1 + taxRate / 100)) }} (brutto)
                    .form-group
                        .form-check
                            input#payment_invoice.form-check-input(
                                type="radio",
                                v-model="paymentMethod",
                                value="invoice"
                            )
                            label.form-check-label(for="payment_invoice") Jährlich per Rechnung
                            small.form-text Rechnungsbetrag: {{ formatCurrency(selectedProduct.price * 12 * (1 + taxRate / 100)) }} (brutto)

        .card.card-body.mb-4
            h3.mb-4 Rechnungsanschrift

            .form-group
                label(for="invoice_company") Firmenname
                input#invoice_company.form-control(name="invoice_company", v-model="profile.name", required)
            .form-row
                .col-md-6
                    .form-group
                        label(for="invoice_firstname") Vorname
                        input#invoice_firstname.form-control(
                            name="invoice_firstname",
                            v-model="profile.contact.firstname",
                            required
                        )
                .col-md-6
                    .form-group
                        label(for="invoice_lastname") Nachname
                        input#invoice_lastname.form-control(
                            name="invoice_lastname",
                            v-model="profile.contact.lastname",
                            required
                        )
            .form-group
                label(for="invoice_street") Straße
                input#invoice_street.form-control(name="invoice_street", v-model="profile.address.street", required)
            .form-row
                .col-md-3
                    .form-group
                        label(for="invoice_zipcode") PLZ
                        input#invoice_zipcode.form-control(
                            name="invoice_zipcode",
                            v-model="profile.address.zipcode",
                            required
                        )
                .col
                    .form-group
                        label(for="invoice_city") Ort
                        input#invoice_city.form-control(name="invoice_city", v-model="profile.address.city", required)
            .form-group
                label(for="invoice_phone") Telefon
                input#invoice_phone.form-control(name="invoice_phone", v-model="profile.contact.phone")

            .mt-4(v-if="paymentMethod === 'sepa'")
                h5.mb-4 Zahlungsdaten
                p.small Sie haben die monatliche Zahlung per Lastschrift gewählt.
                .form-group
                    label(for="owner") Kontoinhaber *
                    input#owner.form-control(type="text" name="owner" v-model="owner" required)
                .form-group
                    label(for="iban") IBAN *
                    input#iban.form-control(
                        :class="{ 'is-invalid' : ibanErr}"
                        v-model="iban"
                        v-imask="ibanMask"
                        aria-describedby="iban-invalid"
                        required
                    )
                    small.form-text.text-muted Beispiel: DE89 3704 0044 0532 0130 00
                    .invalid-feedback#iban-invalid
                        | Ihre eingegebene IBAN ist ungültig. Bitte überprüfen Sie die IBAN noch einmal.
                .form-group
                    .form-check
                        input#accept_mandate.form-check-input(type="checkbox", name="accept_mandate", required)
                        label.form-check-label.small(for="accept_mandate")
                            | Ich ermächtige die Marktplatz Mittelstand GmbH &amp; Co. KG, Zahlungen von meinem Konto mittels Lastschrift einzuziehen.
                            | Zugleich weise ich mein Kreditinstitut an, die von der Marktplatz Mittelstand GmbH &amp; Co. KG auf mein Konto gezogenen Lastschriften einzulösen.
                            | Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen.
                            | Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.

        .text-sm-center
            .form-group.mb-4
                .form-check
                    input#accept_terms.form-check-input(type="checkbox", name="accept_terms", required)
                    label.form-check-label(for="accept_terms")
                        | Ich akzeptiere die
                        |
                        a(href="/agb", target="_blank") Allgemeinen Geschäftsbedingungen
                        |
                        | und habe die
                        |
                        a(href="/datenschutz", target="_blank") Datenschutzrichtlinien
                        |
                        | zur Kenntnis genommen.

        .text-center
            button.btn.btn-lg.btn-secondary(type="submit") Zahlungspflichtig bestellen
</template>

<script>
import api from '@/utils/api'
import { mapGetters } from 'vuex'
import { formatDateTime, formatCurrency } from '@/utils/formatters'
import { IMaskDirective } from 'vue-imask'

export default {
    directives: {
        imask: IMaskDirective
    },
    props: {
        selectedProduct: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    emits: [
        'clearSelectedProduct',
        'checkout'
    ],
    data () {
        return {
            taxRate: 19,
            paymentMethod: 'sepa',
            profile: {
                address: {},
                contact: {}
            },
            iban: null,
            owner: null,
            ibanMask: {
                mask: '{##}00 0000 0000 0000 0000 00',
                definitions: {
                    '#': /[A-Z]/
                }
            },
            ibanErr: false
        }
    },
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ])
    },
    async created () {
        this.loadProfile()
    },
    methods: {
        formatCurrency,
        formatDateTime,
        async loadProfile () {
            this.profile = await api.get('/profile').then(res => res.data)
        },
        async performCheckout () {
            const payload = {
                product_id: this.selectedProduct.id,
                product_name: this.selectedProduct.name,
                product_price: this.selectedProduct.price,
                payment_method: this.paymentMethod,
                invoice_company: this.profile.name,
                invoice_firstname: this.profile.contact.firstname,
                invoice_lastname: this.profile.contact.lastname,
                invoice_street: this.profile.address.street,
                invoice_zipcode: this.profile.address.zipcode,
                invoice_city: this.profile.address.city,
                invoice_phone: this.profile.contact.phone
            }

            if (this.paymentMethod === 'sepa') {
                payload.account_iban = this.iban
                payload.account_owner = this.owner
            }

            try {
                await api.post('/profile/upgrade', payload).then(res => res.data)

                this.$emit('checkout')
            } catch (e) {
                if (e.response.status === 400) {
                    if (e.response.data === 'IBAN_NOT_VALID') {
                        this.ibanErr = true
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.checkout {
    .product-icon {
        svg {
            fill: $primary;
        }
    }
}
</style>
