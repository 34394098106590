<template lang="pug">
main
    p.small: router-link(to="/") &laquo; Zurück zur Übersicht
    .rating
        .rating-author Von {{ author }} am {{ formatDate(rating.createdAt) }}

        .rating-stars
            .total
                rating-stars(:rating="rating.total")
                span {{ rating.total }} von 5 Sternen
            .detail
                span Qualität: {{ rating.criteria.quality }} / 5
                span Pünktlichkeit: {{ rating.criteria.punctuality }} / 5
                span Service: {{ rating.criteria.service }} / 5
                span Preis/Leistung: {{ rating.criteria.price }} / 5

        .rating-title {{ rating.title }}
        p {{ rating.body }}

        .answer
            .title Ihre Antwort
            textarea(v-model="answerText", placeholder="Bitte geben Sie hier Ihre Antwort ein.", rows=6, maxlength=1000)
            button.btn.btn-primary(@click="sendAnswer") Antwort senden
</template>

<script>
import { formatDate } from '@/utils/formatters'
import RatingStars from '@/components/RatingStars'

export default {
    components: {
        RatingStars
    },
    props: {
        rating: {
            type: Object,
            required: true
        },
        answer: {
            type: Object,
            required: false,
            default: null
        }
    },
    emits: [
        'answer'
    ],
    data () {
        return {
            answerText: null
        }
    },
    computed: {
        author () {
            if (this.rating.author.city) {
                return `${this.rating.author.name} (${this.rating.author.city})`
            }
            return this.rating.author.name
        }
    },
    async created () {
        if (this.answer) {
            this.answerText = this.answer.body.text
        }
    },
    methods: {
        formatDate,
        sendAnswer () {
            if (!this.answerText) {
                return
            }

            this.$emit('answer', { rating: this.rating.id, body: { text: this.answerText } })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.rating {
    border: 1px solid $gray-300;
    margin-bottom: 1.5rem;
    padding: 1rem;
}

.rating-author {
    color: $text-muted;
    font-size: $font-size-sm;
    margin-bottom: 0.5rem;
}

.rating-stars {
    font-size: $font-size-sm;
    margin-bottom: 1rem;

    .total {
        align-items: center;
        display: flex;
        margin-bottom: 0.5rem;

        span {
            line-height: 16px; // same as ratingstars svg height
            margin-left: 0.5rem;
        }
    }

    .detail {
        color: $text-muted;

        span {
            margin-right: 0.5rem;
        }
    }
}

.rating-title {
    font-weight: $font-weight-bold;
    margin-top: 1rem;
}

.answer {
    margin-top: 2rem;

    .title {
        font-weight: $font-weight-bold;
        margin: 1rem 0;
    }

    .btn {
        display: block;
        margin-left: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: 700;
        border-radius: 0.9375rem;
    }

    textarea {
        width: 100%;
        border-radius: 0.8rem;
        border: 1px solid #C4C4C4;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        padding-top: 1rem;
        padding-left: 0.5rem;
    }

    textarea::placeholder {
        color: #C4C4C4
    }

    textarea:focus {
        outline: none !important;
    }
}

</style>
