<template lang="pug">
main

    .text-center(v-if="error")
        p.mb-5 Bei der Erstellung Ihrer Kündigungsbestätigung ist etwas schief gelaufen.
        p.mb-5 Bitte melden Sie sich bei unserem Kundenservice unter:
        a.h2(href="tel:+4991198103245") 0911 - 981 03 245

    .text-center(v-else-if="success")
        .success-icon.mx-auto.mb-4(style="width: 3rem")
            include ../../assets/icons/check.svg

        h1.h2.mb-4 Vielen Dank

        p.mb-5 Ihre Kündigungsbestätigung wird nun als PDF heruntergeladen

        router-link.btn.btn-secondary(:to="`/${secret}`") Zurück zur Anfragenübersicht

    form(v-else, @submit.prevent="submit")

        h1.h2.mb-4 Ihre Kündigung

        p Bitte teilen Sie uns den Grund Ihrer Kündigung mit und laden Sie anschließend Ihre Kündigungsbestätigung als PDF herunter:

        .form-group.mt-5
            .form-check.mb-2(v-for="(reason, idx) of availableReasons")
                input.form-check-input(
                    :id="`cbReason_${idx}`"
                    type="checkbox",
                    v-model="form.selectedReasons",
                    :value="reason"
                )
                label.form-check-label(:for="`cbReason_${idx}`") {{ reason }}
            .form-check.mb-2
                input.form-check-input(
                    id="cbReason_other"
                    type="checkbox",
                    v-model="form.selectedReasons",
                    value="Anderer Grund"
                )
                label.form-check-label(for="cbReason_other") Anderer Grund:
                input.form-control.mt-2(v-model="form.otherReason", style="max-width:20rem", placeholder="Bitte geben Sie sonstige Gründe an")

        .mt-5
            button.btn.btn-primary.btn-lg(:disabled="form.selectedReasons.length === 0") Kündigungsbestätigung herunterladen

</template>

<script>
import api from '@/utils/api'
import saveAs from 'save-as'
import { mapGetters } from 'vuex'

const availableReasons = [
    'Zu wenig Anfragen',
    'Qualität der Anfragen',
    'Zu teuer',
    'Zu hohe Auslastung',
    'Vorsorgliche Kündigung'
]

export default {
    data () {
        return {
            availableReasons,
            form: {
                selectedReasons: [],
                otherReason: ''
            },
            profile: null,
            vertical: null,
            success: false,
            error: false
        }
    },
    computed: {
        ...mapGetters([
            'secret'
        ])
    },
    async created () {
        this.profile = await api.get('/profile').then(res => res.data)
        this.vertical = await api.get('/profile/vertical').then(res => res.data)
    },
    methods: {
        async submit () {
            try {
                const data = await api.post('/profile/cancellation-confirmation', this.form, { responseType: 'blob' }).then(res => res.data)
                this.success = true

                // Download PDF
                const blob = new Blob([data], { type: 'application/pdf' })
                saveAs(blob, 'kuendigungsbestaetigung.pdf')
            } catch (err) {
                console.error(err)
                this.error = true
            }
        }
    }
}
</script>
