<template lang="pug">
main(v-if="isLoading")
    .d-flex.align-items-center.flex-column.my-5
        .spinner-border.text-primary
        .mt-3 Lade Bewertungen...

main(v-else)
    p.small: router-link(to="/") &laquo; Zurück zur Übersicht
    h1.h3.mb-3 Ihre Bewertungen
    p Hier können Sie Ihre Bewertungen einsehen und auf diese reagieren. Sie möchten mehr Bewertungen erhalten? Dann bestellen Sie sich jetzt Ihre eigenen Bewertungskarten oder teilen Sie Ihren personalisierten QR-Code mit Ihren Kunden.

    nav.d-flex.justify-content-around.my-4
        router-link.btn.btn-primary.w-25(to="/bewertungen") Bewertungen
        //- router-link.btn.btn-outline-primary.w-25(to="/bewertungen") Bewertungskarten
        router-link.btn.btn-outline-primary.w-25(to="/bewertungen/qrcode") QR-Code

    .ratings-list(v-if="ratings.length")
        rating(v-for="rating of ratings", :rating="rating", :answers-total="answersTotal")

    template(v-else)
        .alert.alert-dark.bg-light.my-5
            h4 Ups!
            em Sie haben bisher noch keine Bewertungen.

        h4.mb-5 Wussten Sie, dass Bewertungen maßgeblich für die Kaufentscheidung potenzieller Kunden sind?
        .row
            .col-md-6
                ul
                    li.mb-4 Mehr als die Hälfte aller Verbraucher lesen vor der Wahl eines Dienstleisters Online-Bewertungen.
                    li.mb-4 Bei 80% der Kunden haben Bewertungen einen maßgeblichen Einfluss auf die Kaufentscheidung.
                    li.mb-4 Auch für 38% der Fachkräfte sind die Bewertungen des Arbeitgebers bei der Jobsuche von entscheidender Bedeutung.
            .col-md-5.offset-md-1
                img.img-fluid(src="@/assets/illustrations/rating.svg")
</template>

<script>
import api from '@/utils/api'
import { mapGetters } from 'vuex'
import Rating from './../_Rating.vue'

export default {
    components: {
        Rating
    },
    data () {
        return {
            isLoading: true,
            ratings: [],
            answersTotal: 0
        }
    },
    computed: {
        ...mapGetters([
            'secret'
        ])
    },
    async created () {
        await this.loadRatings()
    },
    methods: {
        async loadRatings () {
            this.isLoading = true

            this.ratings = await api.get('/ratings').then(res => res.data)
            this.isLoading = false

            this.answersTotal = this.ratings.filter(r => r.answer ? !!r.answer.text : false).length
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

nav {
    @include media-breakpoint-down(md) {
        flex-direction: column;

        .btn {
            margin-bottom: 0.4rem;
            width: 100% !important;
        }
    }
}

.ratings-list {
    margin-top: 3rem;
}
</style>
