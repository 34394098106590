import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from './router'

const getDefaultState = () => {
    return {
        token: '',
        profile: null,
        profileLink: '',
        hasCredentials: false,
        companyOfTheMonth: null,
        vertical: {},
        loggedin: false,
        secret: ''
    }
}

export default createStore({
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        token: state => state.token,
        hasCredentials: state => state.hasCredentials,
        loggedin: state => state.loggedin,
        profile: state => state.profile,
        profileLink: state => state.profileLink,
        vertical: state => state.vertical,
        secret: state => state.secret,
        companyOfTheMonth: state => state.companyOfTheMonth
    },
    mutations: {
        UPDATE: (state, payload) => {
            if (payload.token) {
                state.token = payload.token
            }
            if (payload.profile) {
                state.profile = payload.profile
            }
            if (payload.profileLink) {
                state.profileLink = payload.profileLink
            }
            if (payload.hasCredentials) {
                state.hasCredentials = payload.hasCredentials
            }
            if (payload.vertical) {
                state.vertical = payload.vertical
            }
            if (payload.loggedin) {
                state.loggedin = payload.loggedin
            }
            if (payload.secret) {
                state.secret = payload.secret
            }
            if (payload.companyOfTheMonth) {
                state.companyOfTheMonth = payload.companyOfTheMonth
            }
        },
        SET_LOGGEDIN: (state, payload) => {
            state.loggedin = payload.loggedin
        },
        SET_SECRET: (state, payload) => {
            state.secret = payload.secret
        },
        RESET: state => {
            const defaultState = getDefaultState()
            state.token = defaultState.token
            state.secret = defaultState.secret
            state.profile = defaultState.profile
            state.profileLink = defaultState.profileLink
            state.loggedin = defaultState.loggedin
            state.companyOfTheMonth = defaultState.companyOfTheMonth
        }
    },
    actions: {
        changeLoggedin: ({ commit }, { loggedin }) => {
            commit('SET_LOGGEDIN', { loggedin })
        },
        changeSecret: ({ commit }, { secret }) => {
            commit('SET_SECRET', { secret })
        },
        login: ({ commit }, payload) => {
            commit('UPDATE', payload)
        },
        logout: ({ commit }) => {
            commit('RESET', '')
            router.push('/einloggen')
        }
    }
})
