<template lang="pug">
.row
    .col-md-6: h3.mb-3 Weitere Vorteile für Sie
    .col-md-6.text-md-right.mt-2.mb-4: router-link(:to="`/${secret}/partner`") Unsere Vorteilsprogramme &raquo;

    //- First Banner
    template(v-if="vertical.name === 'Heizungsbau.net' || vertical.name === 'Sanitaer.org' || vertical.name === 'Klimatechniker.net'")
        banner.col-md-6(id="meinshk")
    template(v-else-if="vertical.name === 'Bauunternehmen.org' || vertical.name === 'Trockenbauunternehmen.net'")
        banner.col-md-6(id="meinmaurer")
    template(v-else)
        banner.col-md-6(id="firstdebit")

    //- Second Banner
    template(v-if="vertical.name === 'Heizungsbau.net' || vertical.name === 'Sanitaer.org' || vertical.name === 'Klimatechniker.net'")
        banner.col-md-6(id="luftverbundrechner")
    template(v-else-if="vertical.name === 'Dachdecker.com'")
        banner.col-md-6(id="meindachdecker")
    template(v-else-if="vertical.name === 'Tischler-Schreiner.org'")
        banner.col-md-6(id="meinzimmerer")
    template(v-else)
        banner.col-md-6(id="designenlassen")
</template>

<script>
import { mapGetters } from 'vuex'
import Banner from '@/components/Banner'

export default {
    components: {
        Banner
    },
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ])
    }
}
</script>
