<template lang="pug">
ProductsTab(v-if="currentTab === 'products'", @input="selectedProduct = $event")
CheckoutTab(
    v-if="currentTab === 'checkout'",
    :selectedProduct="selectedProduct",
    @clearSelectedProduct="selectedProduct = {}",
    @checkout="checkoutConfirmed = true"
)
SuccessTab(v-if="currentTab === 'success'")
</template>

<script>
import { mapGetters } from 'vuex'
import CheckoutTab from './_Checkout'
import ProductsTab from './_Products'
import SuccessTab from './_Success'

export default {
    components: {
        CheckoutTab,
        ProductsTab,
        SuccessTab
    },
    data () {
        return {
            selectedProduct: {},
            checkoutConfirmed: false
        }
    },
    computed: {
        ...mapGetters([
            'vertical'
        ]),
        currentTab () {
            if (this.checkoutConfirmed) {
                return 'success'
            }

            if (this.selectedProduct.id) {
                return 'checkout'
            }

            return 'products'
        }
    }
}
</script>
