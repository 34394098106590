<template lang="pug">
main
    .row(v-if="activeTab === 'username'")
        .col-md-6
            form(@submit.prevent="reset")
                h2 Passwort vergessen
                p.text-muted Keine Sorge! Das passiert den Besten.
                p Geben Sie nachfolgend Ihre Anmelde-E-Mail-Adresse an. Wir senden Ihnen dann einen Link zum Zurücksetzen Ihres Passwortes zu.
                .form-group
                    input.form-control(type="email", placeholder="E-Mail-Adresse", v-model="username", required)
                .text-success.mb-3(v-if="submitted")
                    | Wir haben die Mail zum Zurücksetzen des Passwortes verschickt. Bitte prüfen Sie ggf. ob diese im Spam gelandet ist.
                button.btn.btn-secondary.px-4(type="submit") Passwort zurücksetzen

    form(v-if="activeTab === 'password'", @submit.prevent="change")
        h2 Passwort ändern
        .row
            .col-md-6
                .form-group
                    label Neues Passwort
                    input.form-control(type="password", required, v-model="password")
                .form-group
                    label Passwort wiederholen
                    input.form-control(type="password", required, v-model="password2")
            .col-md-6
                .font-weight-bold Tipps für ein sicheres Passwort:
                .password-tip.d-flex.align-items-center(:class="{ completed: hasCompletedLength }")
                    include ../../assets/icons/check.svg
                    span Mindestens 8 Zeichen
                .password-tip.d-flex.align-items-center(:class="{ completed: hasBothCaseLetters }")
                    include ../../assets/icons/check.svg
                    span Groß- und Kleinbuchstaben
                .password-tip.d-flex.align-items-center(:class="{ completed: hasSpecialCharacter }")
                    include ../../assets/icons/check.svg
                    span Mindestens 1 Sonderzeichen
        .text-success.mb-3(v-if="successfulReset")
            | Ihr neues Passwort ist gespeichert. Sie können sich nun wie gewohnt wieder einloggen!
        .text-danger.mb-3(v-if="failedReset")
            | Das hat leider nicht funktioniert. Bitte versuchen Sie es noch einmal.
        button.btn.btn-secondary.px-4(type="submit", :disabled="!passwordsMatch") Passwort ändern
</template>

<script>
import api from '@/utils/api'

export default {
    data () {
        return {
            code: '',
            username: '',
            password: '',
            password2: '',
            submitted: false,
            successfulReset: false,
            failedReset: false
        }
    },
    computed: {
        activeTab () {
            return this.code ? 'password' : 'username'
        },
        passwordsMatch () {
            return this.password && this.password2 && (this.password === this.password2)
        },
        hasCompletedLength () {
            return this.password.length >= 8
        },
        hasBothCaseLetters () {
            const hasUppercase = /[A-Z]/.test(this.password)
            const hasLowercase = /[a-z]/.test(this.password)

            return hasUppercase && hasLowercase
        },
        hasSpecialCharacter () {
            return /[^a-zA-Z0-9]+/.test(this.password)
        }
    },
    async created () {
        const { email, code } = this.$route.query

        if (email && code) {
            this.code = code
            this.username = email
        }
    },
    methods: {
        async reset () {
            const username = this.username
            await api.post('/profile/reset', { username })
            this.submitted = true
            this.username = ''
        },
        async change () {
            const { code, username, password } = this
            try {
                await api.post('/profile/reset', { code, username, password })
            } catch (err) {
                this.failedReset = true
                return
            }
            this.successfulReset = true
            this.password = ''
            this.password2 = ''
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    margin: auto;
    margin-top: 5rem;
    max-width: 30rem;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.password-tip {
    margin-top: 1rem;

    svg {
        fill: $gray-400;
        height: 1rem;
        margin-right: 0.4rem;
        width: 1rem;
    }

    &.completed {
        svg {
            fill: $success;
        }
    }
}
</style>
