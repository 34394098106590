<template lang="pug">
main
    p.small: router-link(:to="`/${secret}/partner`") &laquo; Zurück zu den Partnerprogrammen
    h1.h2.my-4
        | MeinSHK - Schluss mit Zettelwirtschaft
        br
        small Die App von Handwerkern für Handwerker

    .row
        .col-md-8
            p
                | Mit der MeinSHK-App digitalisieren Sie die wichtigsten Prozesse Ihres Betriebes.
                | Sie haben jederzeit und überall alle wichtigen Informationen für Ihre Mitarbeiter,
                | Kunden und Partner-Firmen zur Hand. Jeder Mitarbeiter bekommt seinen eigenen persönlichen App-Zugang
                | und erfasst Zeiten, schreibt Bautagebücher &amp; Rapporte, hinterlegt Fotos und kann einsehen, wann und
                | mit wem er am nächsten Tag für welche Baustelle eingeplant ist.
            .mb-2
                span.text-primary.mr-2 ✓
                | Funktionen: Abnahmen, Arbeitspläne, Bautagebuch, Chat, Zeiterfassung u.v.m.
            .mb-2
                span.text-primary.mr-2 ✓
                | Kinderleichte Bedienung: Unabhängig von technologischer Erfahrung
            .mb-2
                span.text-primary.mr-2 ✓
                | Verwaltungssystem für das Büro: Wir bieten auch eine Desktop Anwendung für Ihren PC
            .mb-2
                span.text-primary.mr-2 ✓
                | Service: Wir stehen in dauerndem Austausch mit unseren Betrieben und helfen kostenlos,
                | das Verwaltungssystem und die App einzurichten.

        .col-md-4
            img.w-100.my-4(src="@/assets/logo-meinshk.png")

    .d-flex.align-items-top.my-4
        .font-weight-bold.mr-2 Unser Angebot:
        .price.mx-3 3 Monate kostenlos und unverbindlich testen

    .d-flex.align-items-center.mt-4
        a.btn.btn-secondary.mr-4(href="https://www.mein-shk-app.de?utm_source=digitale_seiten", target="_blank", rel="noopener") Hier starten!
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'secret'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

figure.check {
    margin: 0;
    margin-right: 0.4rem;
    width: 1rem;
}

.price {
    color: $secondary;
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    line-height: 1;
}

.price-old {
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
}
</style>
