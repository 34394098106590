<template lang="pug">
main
    p.small: router-link(:to="`/${secret}`") &laquo; Zurück zur Anfragenübersicht
    h1.h2.my-4 Partnerprogramme
    p Profitieren Sie als Kunde von {{ vertical.name }} durch exklusive Angebote unserer Kooperationspartner. Wir aktualisieren unser Partnerprogramm regelmässig. Daher: Reinschauen lohnt sich!

    hr.my-5

    .row
        banner.col-md-6(id="designenlassen")
        banner.col-md-6(id="firstdebit")
        banner.col-md-6(id="luftverbundrechner")
        banner.col-md-6(id="meindachdecker")
        banner.col-md-6(id="meinmaurer")
        banner.col-md-6(id="meinzimmerer")
</template>

<script>
import { mapGetters } from 'vuex'
import Banner from '../../components/Banner.vue'

export default {
    components: {
        Banner
    },
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ])
    }
}
</script>
