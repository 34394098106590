<template lang="pug">
main
    .row
        .col-md-6.offset-md-3
            form(@submit.prevent="login")
                h2 Login

                .form-group
                    label E-Mail
                    input.form-control(type="email", v-model="username")
                .form-group
                    label Passwort
                    input.form-control(type="password", v-model="password")
                .text-center.text-danger.mb-3(v-if="numFailedLogins")
                    | Ihr Nutzername oder Passwort sind falsch. Sie haben noch {{ 5 - numFailedLogins }} Versuche.
                .d-flex.align-items-center.justify-content-center
                    button.btn.btn-secondary.px-4(type="submit") Einloggen
                    router-link.ml-3(to="/passwort-vergessen") Passwort vergessen
</template>

<script>
import api from '@/utils/api'

export default {
    data () {
        return {
            numFailedLogins: 0,
            username: '',
            password: ''
        }
    },
    async created () {
        const profile = await api.get('/profile').then(res => res.data)
        if (profile && !profile.credentials.active) {
            this.$router.push('/registrieren')
        }
    },
    methods: {
        async login () {
            const { username, password } = this
            try {
                const result = await api.post('/token', { username, password }).then(res => res.data)
                this.$store.dispatch('login', result)
                this.$router.push('/')
            } catch (err) {
                this.numFailedLogins++
                if (this.numFailedLogins >= 5) {
                    this.$router.push('/passwort-vergessen')
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    margin: auto;
    margin-top: 5rem;
    max-width: 30rem;
}
</style>
