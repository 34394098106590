<template lang="pug">
.d-flex.align-items-center.flex-column.my-5(v-if="isLoading")
    .spinner-border.text-primary
    .mt-3 Lade Profildaten...

form(v-else, @submit.prevent="updateProfile")
    .infobox.mt-5.mb-4
        img(src="@/assets/icons/info-alt.svg", width="40", height="40")
        p
            | Für alle Anpassungen, die Sie hier nicht finden (beispielsweise Firmenname) rufen Sie bitte direkt unseren
            br
            | Kundenservice unter der #[strong 0911 - 981 03 259] an. Unsere Kollegen und Kolleginnen helfen Ihnen gerne weiter!

    .row
        fieldset.col-md-6
            legend.my-4 Firmenanschrift

            .form-group
                label Straße &amp; Hausnummer
                input.form-control(placeholder="Straße + Hausnummer", v-model="profile.address.street")

            .form-row
                .form-group.col-4
                    label PLZ
                    input.form-control(placeholder="PLZ", v-model="profile.address.zipcode")
                .form-group.col-8
                    label Stadt
                    input.form-control(placeholder="Stadt", v-model="profile.address.city")

        fieldset.col-md-6
            legend.mb-4 Ansprechpartner

            .form-row
                .form-group.col-6
                    label Anrede
                    input.form-control(placeholder="Herr / Frau", v-model="profile.contact.gender")
                .form-group.col-6
                    label Titel
                    input.form-control(placeholder="Dr. Prof.", v-model="profile.contact.title")
            .form-row
                .form-group.col-6
                    label Vorname
                    input.form-control(placeholder="Vorname", v-model="profile.contact.firstname")
                .form-group.col-6
                    label Nachname
                    input.form-control(placeholder="Nachname", v-model="profile.contact.lastname")

        fieldset.col-12
            legend.my-4 Kontaktdaten

            .form-row
                .form-group.col-4
                    label Telefon
                    input.numberTyper.form-control(placeholder="Telefon", v-model="profile.contact.phone")
                    small.form-text.text-muted
                .form-group.col-4
                    label Mobil
                    input.numberTyper.form-control(placeholder="Mobil", v-model="profile.contact.mobile")
                .form-group.col-4
                    label Fax
                    input.numberTyper.form-control(placeholder="Fax", v-model="profile.contact.fax")
                    small.form-text.text-muted
                .form-group.col-6
                    label Webseite
                    input.form-control(placeholder="https://", v-model="profile.contact.website")
                .form-group.col-6
                    label Facebook-Profil
                    input.form-control(placeholder="https://www.facebook.com/...", v-model="profile.contact.facebook")

    .d-flex.align-items-center.justify-content-center.mt-5
        button.btn.btn-secondary.mr-4(type="submit") Änderungen speichern
        a.font-weight-bold(:href="profile.url", target="_blank") Profil aufrufen

    .text-center.text-success.mt-4(v-if="success") ✓ Änderungen erfolgreich gespeichert
</template>

<script>
import api from '@/utils/api'

import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            isLoading: true,
            success: false,
            profile: null
        }
    },
    computed: {
        ...mapGetters([
            'vertical'
        ])
    },
    async created () {
        this.profile = await api.get('/profile').then(res => res.data)
        this.isLoading = false
    },
    methods: {
        async updateProfile () {
            await api.post('/profile', this.profile)
            this.success = true
            // Reload Profile Data
            this.profile = await api.get('/profile').then(res => res.data)
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/theme";

.infobox {
    align-items: center;
    border: 3px solid $primary;
    border-radius: 13px;
    display: flex;
    padding: 1rem;

    img {
        margin-right: 1rem;
    }

    p {
        line-height: 1.2;
        margin-bottom: 0;
    }
}
</style>
