<template lang="pug">
main(v-if="isLoading")
    .d-flex.align-items-center.flex-column.my-5
        .spinner-border.text-primary
        .mt-3 Lade Kundenanfrage...

main(v-else)
    p.small: router-link(:to="`/${secret}`") &laquo; Zurück zur Anfragenübersicht

    .border.p-2.my-4.d-flex(v-if="!hasCredentials")
        img(src="@/assets/icons/info.svg", width="40", height="40")
        span.ml-2
            router-link(to="/einloggen") Hier gelangen Sie in Ihren persönlichen Bereich
            |
            | - dort können Sie unter anderem Ihre Daten abändern oder Bilder hochladen.
            br
            | Einfach einmalig Zugangsdaten festlegen und los geht's!

    h1.h3 Ihre Kundenanfrage aus {{ assignment.lead.address.zipcode }} {{ assignment.lead.address.city }}

    //- Show an Intro Text
    template(v-if="assignment.status === 'open'")
        p
            | Entscheiden Sie anhand der nachstehenden Anfragendetails, ob Sie die "Anfrage annehmen" oder "Anfrage ablehnen" möchten.
            br
            | Wenn Sie auf "Kontaktdaten erhalten" klicken empfehlen wir Ihnen, sich zeitnah mit dem Kunden in Verbindung setzen, um sich den Auftrag zu sichern.

    //- Status Boxes
    .row.text-center.mt-4
        .col-md-6.mb-4
            .border-top.border-bottom.py-2
                .text-muted Anfrage zugestellt
                | {{ formatDateTime(assignment.createdAt) }} Uhr
        .col-md-6.mb-4
            .border-top.border-bottom.py-2
                .text-muted Status
                span(v-if="assignment.status === 'open'") noch {{ remainingDateTime }} verfügbar
                span(v-else-if="assignment.status === 'accepted'") am {{ formatDate(assignment.tracking.acceptedAt) }} angenommen
                span(v-else-if="assignment.status === 'declined'") am {{ formatDate(assignment.tracking.declinedAt) }} abgelehnt
                span(v-else-if="assignment.lead.status === 'stopped'") Anfrage storniert
                span(v-else-if="assignment.status === 'expired'") Frist abgelaufen

    //- Show Customers an Alert, when they hit their Accept Limit
    template(v-if="isLimited && assignment.status === 'open'")
        .text-center.mb-4(v-if="profile.credits")
            .alert.alert-secondary
                strong Zum Akzeptieren dieser Anfrage wird Ihr Anfragen-Kontingent verwendet
                br
                span Ihnen {{ profile.credits > 1 ? `stehen noch ${profile.credits} Anfragen` : 'steht noch eine Anfrage' }} zur Verfügung.
        .text-center.mb-4(v-else-if="profile.product")
            .alert.alert-secondary
                strong Ihr Kontingent ist ausgeschöpft
                br
                span Sie haben bereits alle in Ihrem Paket enthaltenen Anfragen akzeptiert.
                br
                router-link(:to="`/${secret}/upgrade`") Buchen Sie jetzt ein Paket-Upgrade mit größerem Kontingent &raquo;

    //- Show a Blocking Banner for Nonpaying Customers
    template(v-if="isNonpaying")
        .text-center.mb-4.border.p-4
            img.mb-2(src="@/assets/icons/invoice.svg", width="40", height="40")
            .h3.mb-3 Bitte begleichen Sie Ihre offene Rechnung
            p
                | Um Anfragen wieder Annehmen oder Ablehnen zu können, müssen Sie bitte erst Ihre offene Rechnung an uns begleichen.
                | Falls Sie hierzu Fragen haben, wenden Sie sich gerne an #[a(href="mailto:info@digitaleseiten.de") info@digitaleseiten.de]
                | oder rufen Sie uns unter der #[a(href="tel:+49911340983320") 0911 / 3409 83320] an.

    //- Accept/Decline Buttons
    template(v-else-if="assignment.status === 'open'")
        .row
            .col-md-6.mb-4
                button.btn.btn-block.btn-outline-success(@click="acceptAssignment()") Kontaktdaten erhalten
            .col-md-6.mb-4
                button.btn.btn-block.btn-outline-danger(@click="declineModal.show = true") Anfrage ablehnen

    //- Contact Details of Accepted Assignment
    template(v-else-if="assignment.status === 'accepted'")
        .border.border-primary.p-4.mb-4
            p.font-weight-bold Bitte kontaktieren Sie als nächstes den Kunden:

            .row
                .col.mb-4.mb-sm-0
                    | {{ assignment.lead.contact.firstname }} {{ assignment.lead.contact.lastname }}
                    br
                    | {{ assignment.lead.address.street }}
                    br
                    | {{ assignment.lead.address.zipcode }} {{ assignment.lead.address.city }}
                .col.mb-4.mb-sm-0
                    .mb-2
                        strong E-Mail:
                        br
                        | {{ assignment.lead.contact.email }}
                    .mb-2(v-if="assignment.lead.contact.phone")
                        strong Telefon:
                        br
                        | {{ assignment.lead.contact.phone }}
                    .mb-2(v-if="assignment.lead.contact.mobile")
                        strong Mobil:
                        br
                        | {{ assignment.lead.contact.mobile }}
                .col
                    p: a(:href="`mailto:${assignment.lead.contact.email}`") per E-Mail kontaktieren
                    p: a(:href="`/zentrale/${secret}/${assignment.id}.pdf`", target="_blank") Auftrag als PDF herunterladen

    //- Assignment Details
    .border
        .row
            .col-md-7
                .p-4
                    p
                        strong Entfernung zum Auftragsort
                        br
                        span(v-if="profile.product") {{ distanceToLead }}
                        template(v-else)
                            | Diese Funktion steht Ihnen ab dem "Business-Paket" zur Verfügung.
                            br
                            router-link(:to="`/${secret}/upgrade`") Erfahren Sie mehr über unsere Pakete &raquo;

                    p(v-for="detail in assignment.lead.details")
                        strong {{ detail.question }}
                        br
                        | {{ detail.answer }}

                    template(v-if="assignment.lead.media.length")
                        strong Anhänge
                        br
                        em(v-if="assignment.status !== 'accepted'") Die Fotos werden erst nach erfolgter Anfragenannahme unverpixelt dargestellt.
                        .assignment-media.card-columns.mt-3
                            .card(v-for="media in assignment.lead.media")
                                a(:href="media.path", target="_blank")
                                    img.card-img(:src="media.thumbnailPath")

            .col-5.d-none.d-md-block
                #geo-toolbox.h-100
                    google-map.h-100(
                        :destination="assignment.lead.address.geo.coordinates",
                        :origin="profile.product ? profile.address.geo.coordinates : [0, 0]",
                        @distance="distanceToLead = $event"
                    )

    //- Mobile Accept/Decline Buttons
    .row.mt-4(v-if="assignment.status === 'open' && !isNonpaying")
        .col-md-6.mb-4
            button.btn.btn-block.btn-outline-success(onclick="acceptAssignment()") Kontaktdaten erhalten
        .col-md-6.mb-4
            button.btn.btn-block.btn-outline-danger(@click="declineModal.show = true") Anfrage ablehnen

    hr

    //- FAQ
    .faq.my-5
        h2.title.text-center Sie haben noch Fragen bevor Sie die Anfrage annehmen?
        p.text-center Hier beantworten wir Ihnen die meist gestellten Fragen unserer Fachbetriebe.

        .accordion.mt-5
            faq(v-for="faq in faqs", :data="faq")

    hr

    partner-banner.mt-5

    //- Decline Modal
    .modal-backdrop.fade.show(v-if="declineModal.show")
    .modal(:class="{ 'd-block': declineModal.show }", tabindex="-1", role="dialog")
        .modal-dialog: .modal-content
            .modal-header
                strong.modal-title Ich lehne diese Anfrage ab, weil
                button.close(type="button", @click="declineModal.show = false")
                    span(aria-hidden="true") &times;
            .modal-body
                form.form.mb-0(@submit.prevent="declineAssignment()")
                    .form-check
                        input#declinedReason_too_small.form-check-input(
                            type="radio",
                            v-model="declineModal.reason",
                            value="too_small"
                        )
                        label(for="declinedReason_too_small") der Auftragswert zu gering ist.
                    .form-check
                        input#declinedReason_wrong_service.form-check-input(
                            type="radio",
                            v-model="declineModal.reason",
                            value="wrong_service"
                        )
                        label(for="declinedReason_wrong_service") ich die gewünschte Dienstleistung nicht anbiete.
                    .form-check
                        input#declinedReason_too_big.form-check-input(
                            type="radio",
                            v-model="declineModal.reason",
                            value="too_big"
                        )
                        label(for="declinedReason_too_big") der Auftrag mehr Mitarbeiter erfordert als ich beschäftige.
                    .form-check
                        input#declinedReason_too_far.form-check-input(
                            type="radio",
                            v-model="declineModal.reason",
                            value="too_far"
                        )
                        label(for="declinedReason_too_far") die Entfernung zum Auftragsort zu weit ist.
                    .form-check
                        input#declinedReason_no_time.form-check-input(
                            type="radio",
                            v-model="declineModal.reason",
                            value="no_time"
                        )
                        label(for="declinedReason_no_time") ich ausgelastet bin.
                    .form-check
                        input#declinedReason_other.form-check-input(
                            type="radio",
                            v-model="declineModal.reason",
                            value="other"
                        )
                        label(for="declinedReason_other") Sonstige Gründe:
                        br
                        input.form-control(type="text", v-model="declineModal.reasonText")

                    .row.mt-4
                        .col-sm-6.order-sm-1.mb-2
                            button.btn.btn-block.btn-danger(type="submit") Anfrage ablehnen
                        .col-sm-6
                            button.btn.btn-block.btn-light(type="button", @click="declineModal.show = false") Abbrechen

    //- Feedback Modal
    .modal-backdrop.fade.show(v-if="acceptFeedback.show")
    .modal(:class="{ 'd-block': acceptFeedback.show }", tabindex="-1", role="dialog")
        .modal-dialog: .modal-content
            .modal-header
                strong.modal-title Sie können diese Anfrage nicht annehmen
                button.close(type="button", @click="acceptFeedback.show = false")
                    span(aria-hidden="true") &times;
            .modal-body
                template(v-if="acceptFeedback.code === 'ACCEPT_LIMIT_EXCEEDED'")
                    p Ihr Kontingent zur Annahme von Anfragen ist bereits ausgeschöpft.
                    router-link(:to="`/${secret}/upgrade`") Buchen Sie jetzt ein Upgrade um weitere Anfragen annehmen zu können &raquo;

                template(v-if="acceptFeedback.code === 'HIGH_VALUE_LEAD'")
                    p Um Anfragen mit einem hohen Auftragswert annehmen zu können, benötigen Sie ein Upgrade für Ihr Paket.
                    router-link(:to="`/${secret}/upgrade`") Buchen Sie jetzt ein Upgrade für Anfragen mit hohem Auftragswert &raquo;

                hr

                .text-center(v-if="acceptFeedback.callme.success")
                    .success-icon.mx-auto.mb-4(style="width: 3rem")
                        include ../../assets/icons/check.svg
                    .font-weight-bold Vielen Dank für Ihre Anfrage
                    p Wir melden uns schnellstmöglich bei Ihnen
                form(v-else, @submit.prevent="requestCallback")
                    p.font-weight-bold Oder vereinbaren Sie einfach einen Rückruf von {{ vertical.name }}
                    .form-group
                        label(for="phone") Ihre Telefonnummer:
                        input.form-control(type="text", v-model="acceptFeedback.callme.phone", required="required")
                    button.btn.btn-secondary(type="submit") Rückruf vereinbaren &raquo;

                hr

                .text-center
                    | Oder rufen Sie uns an, wir beraten Sie gerne:
                    .lead 0911 - 981 03 245
</template>

<script>
import api from '@/utils/api'
import GoogleMap from '@/components/GoogleMap'
import PartnerBanner from './_PartnerBanner.vue'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
import { formatDateTime, formatDate } from '@/utils/formatters'
import Faq from './_Faq.vue'
import faqs from './faqs'

export default {
    components: {
        Faq,
        GoogleMap,
        PartnerBanner
    },
    data () {
        return {
            isLoading: true,
            assignment: null,
            isLimited: false,
            isNonpaying: false,
            profile: null,
            distanceToLead: '',
            declineModal: {
                show: false,
                reason: null,
                reasonText: ''
            },
            acceptFeedback: {
                show: false,
                code: null,
                callme: {
                    success: false,
                    phone: ''
                }
            }
        }
    },
    computed: {
        ...mapGetters([
            'secret',
            'hasCredentials',
            'vertical'
        ]),
        remainingDateTime () {
            const dateTime = DateTime.fromISO(this.assignment.createdAt).plus({ days: 7 })
            const remaining = dateTime.diffNow('hours')
            if (remaining.hours < 1) {
                return dateTime.toRelative({ locale: 'de', unit: 'minutes' }).replace(/^in /, '')
            }
            if (remaining.hours < 48) {
                return dateTime.toRelative({ locale: 'de', unit: 'hours' }).replace(/^in /, '')
            }
            return dateTime.toRelative({ locale: 'de', unit: 'days' }).replace(/^in /, '').replace(/Tagen/, 'Tage')
        },
        faqs () {
            return faqs(this.vertical)
        }
    },
    async created () {
        this.loadAssignment()
    },
    methods: {
        formatDateTime,
        formatDate,
        async loadAssignment () {
            this.isLoading = true
            const result = await api.get(`/assignments/${this.$route.params.assignment}`).then(res => res.data)

            this.assignment = result.assignment
            this.isLimited = result.isLimited
            this.isNonpaying = result.isNonpaying
            this.profile = await api.get('/profile').then(res => res.data)
            this.isLoading = false
        },
        async acceptAssignment () {
            try {
                await api.post(`/assignments/${this.assignment.id}`, { action: 'accept' }).then(res => res.data)
                // No Error thrown? Refresh Assignment to Show Contact Details
                this.loadAssignment()
            } catch (err) {
                if (['ACCEPT_LIMIT_EXCEEDED', 'HIGH_VALUE_LEAD'].includes(err.response?.data)) {
                    this.acceptFeedback.show = true
                    this.acceptFeedback.code = err.response.data
                }
            }
        },
        async declineAssignment () {
            const data = {
                action: 'decline',
                declinedReason: this.declineModal.reason,
                declinedReasonText: this.declineModal.reasonText
            }
            try {
                await api.post(`/assignments/${this.assignment.id}`, data).then(res => res.data)
                this.declineModal.show = false
                // No Error thrown? Refresh Assignment to Show Contact Details
                this.loadAssignment()
            } catch (err) {
                // hmm
            }
        },
        async requestCallback () {
            const sources = {
                ACCEPT_LIMIT_EXCEEDED: 'Popup: Kostenloses Kontingent ausgelaufen',
                HIGH_VALUE_LEAD: 'Popup: High Value Lead'
            }

            const data = {
                assignment: this.assignment.id,
                phone: this.acceptFeedback.callme.phone,
                source: sources[this.acceptFeedback.code]
            }

            await api.post('/profile/callme', data).then(res => res.data)
            this.acceptFeedback.callme.success = true
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.faq {
    .accordion {
        @include media-breakpoint-up(md) {
            margin: auto;
            max-width: 75%;
        }
    }
}
</style>
