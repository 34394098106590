module.exports = vertical => {
    return [{
        question: 'Was passiert, wenn ich nach dem Ansehen der Kundenanfrage auf "Kontaktdaten erhalten" klicke?',
        answer: 'Wenn Sie eine Kundenanfrage annehmen, erscheinen im oberen Bereich die Kontaktdaten des Kunden. Bitte setzen Sie sich bei Interesse am Auftrag über die Kontaktdaten mit dem Kunden in Verbindung, um weitere Details zu klären.' +
        '<br><br>' +
        'Mit dem Klick auf “Kontaktdaten erhalten” schließen Sie keinen Vertrag ab. Erst wenn Sie mehr als zwei kostenlose Anfragen im Jahr annehmen möchten und sich aktiv für eine kostenpflichtige Mitgliedschaft entscheiden, fallen für Sie Kosten an.'
    }, {
        question: 'Wie viele Kundenanfragen erhalte ich über mein kostenloses Profil?',
        answer: 'Über Ihr kostenloses Profil erhalten Sie regelmäßig E-Mails mit Kundenanfragen. Sie können allerdings nur zwei Kundenanfragen im Jahr annehmen. Sobald Sie Ihr kostenloses Kontingent ausgeschöpft haben und mehr Kundenanfragen annehmen möchten, weisen wir Sie darauf hin und bieten Ihnen die Möglichkeit, sich für eine kostenpflichtige Mitgliedschaft zu entscheiden.'
    }, {
        question: 'Kann ich Änderungen an meinem Profil vornehmen?',
        answer: 'Um Änderungen an Ihrem Profil vorzunehmen, müssen Sie für Ihr Profil.' +
        '<br><br>' +
        '1. Falls noch nicht geschehen: Zugangsdaten festlegen oder' +
        '<br><br>' +
        '2. Falls bereits geschehen: Sich einloggen' +
        '<br><br>' +
        'Danach haben Sie Zugriff auf Ihr Profil und können selbstständig folgende Daten ändern und ergänzen:' +
        '<br><br>' +
        '<ul>' +
        '<li>Firmenanschrift,</li>' +
        '<li>Ansprechpartner,</li>' +
        '<li>Kontaktdaten,</li>' +
        '<li>Gründungsjahr,</li>' +
        '<li>Anzahl Mitarbeiter, </li>' +
        '<li>Handelsregister-Eintrag, </li>' +
        '<li>Meisterbetrieb, </li>' +
        '<li>Ausbildungsbetrieb</li>' +
        '</ul>' +
        'Weitere Änderungen oder Ergänzungen, wie beispielsweise Bildmaterial, sind nur mit einem kostenpflichtigen Profil möglich. Auch hier benötigen Sie den Zugang zu Ihrem persönlichen Kundenbereich.' +
        '<br><br>' +
        'Nach dem Speichern sind die Änderungen nach einer letzten Prüfung über unsere Mitarbeiter spätestens nach 5 Werktagen online sichtbar.' +
        '<br><br>' +
        'Wenn Sie Fragen haben oder Unterstützung benötigen, wenden Sie sich gern an unseren Kundenservice. Sie erreichen unsere Mitarbeiter unter kundenservice@digitaleseiten.de oder per Telefon unter 0911/377 50 297'

    }, {
        question: 'Wie lange ist die Vertragslaufzeit?',
        answer: 'Die Vertragslaufzeit beträgt für alle bezahlten Mitgliedschaften 12 Monate und beginnt mit dem Erhalt der Auftragsbestätigung. Die Vertragslaufzeit verlängert sich automatisch jeweils um einen Zeitraum von 12 Monaten, sofern Sie nicht mit einer Frist von drei Monaten vor Ende der Vertragslaufzeit per Post, Fax oder E-Mail kündigen.'
    }, {
        question: 'Wie kündige ich meinen Vertrag?',
        answer: 'Kündigen Sie bitte immer schriftlich und mit rechtsgültiger Unterschrift per Post, Fax oder E-Mail drei Monate vor Ablauf der Vertragslaufzeit.'
    }, {
        question: 'Wie kann ich meine Rechnungsanschrift ändern?',
        answer: 'Bei Änderungswünschen aller Art können Sie sich an unseren Kundenservice wenden. Unseren Kundenservice erreichen Sie telefonisch unter der 0911/377 50 297 oder per Mail unter kundenservice@digitaleseiten.de.'
    }, {
        question: 'Ich habe eine Kundenanfrage aus Versehen angenommen. Kann ich diese wieder zurücknehmen?',
        answer: 'Bitte lesen Sie sich die Kundenanfrage vor einer Annahme sorgfältig durch. Leider können wir eine Anfrage, die Sie bereits angenommen haben, nicht mehr zurücksetzen.'
    }, {
        question: 'Was passiert, wenn ich den Kunden nicht erreiche, nachdem ich eine Anfrage angenommen habe?',
        answer: `Da ${vertical.name} ausschließlich auf die Vermittlung von Kundenanfragen spezialisiert ist, können wir leider keine Garantie dafür übernehmen, ob ein Vertrag zustande kommt.`
    }]
}
