<template lang="pug">
main
    p.small: router-link(:to="`/${secret}/partner`") &laquo; Zurück zu den Partnerprogrammen
    h1.h2.my-4
        | Software zur Berechnung des Verbrennungsluftverbunds
        br
        small Für den Nachweis nach DVGW/TRGI2018

    .row
        .col-md-8
            p
                | Mit dem Luftverbundrechner erstellen und verwalten Sie Ihre Protokolle effizient und einfach.
                | Berechnen Sie mit unserem intuitiven Assistenten den Verbrennungsluftverbund und Prüfen auf Schutzziel 1 &amp; 2.
                | Das fertige Prüfprotokoll können Sie jederzeit als PDF herunterladen.
            .mb-2
                span.text-primary.mr-2 ✓
                | Sämtliche Formeln und Tabellen werden automatisch angewandt - kein Nachschlagen nötig
            .mb-2
                span.text-primary.mr-2 ✓
                | Vernetzt und immer dabei: Sie und Ihre Mitarbeiter haben stets Zugriff auf alle Daten - auch mobil.
            .mb-2
                span.text-primary.mr-2 ✓
                | Umfangreichen Hilfestellungen und Tipps innerhalb des Berechnungsassistenten
            .mb-2
                span.text-primary.mr-2 ✓
                | Inuitive Bedienung und Datenerfassung: Bis zu 70% Zeitersparnis pro Nachweis

        .col-md-4
            img.w-100.my-4(src="@/assets/logo-luftverbundrechner.svg")

    .d-flex.align-items-top.my-4
        .font-weight-bold.mr-2 Unser Angebot:
        .price.mx-3 ab 49 € pro Jahr

    .d-flex.align-items-center.mt-5
        a.btn.btn-secondary.mr-4(:href="`https://www.luftverbundrechner.de/${refCode}`", target="_blank") Hier buchen!
        a.font-weight-bold(:href="`https://www.luftverbundrechner.de/beispiel${refCode}`", target="_blank") 🢒 Beispielberechnung ansehen
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ]),
        refCode () {
            if (this.vertical.name === 'Sanitaer.org') {
                return '?via=dssantiaer'
            }

            if (this.vertical.name === 'Heizungsbau.net') {
                return '?via=dsheizungsbau'
            }

            return ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

figure.check {
    margin: 0;
    margin-right: 0.4rem;
    width: 1rem;
}

.price {
    color: $secondary;
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    line-height: 1;
}

.price-old {
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
}
</style>
