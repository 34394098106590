<template lang="pug">
main
    p.small: router-link(:to="`/${secret}`") &laquo; Zurück zur Übersicht

    h1.mb-5 Top-Betrieb des Monats

    .d-flex.justify-content-between.mb-5
        .text
            .h3.text-secondary.mb-4 Herzlichen Glückwunsch!
            .font-weight-bold.mb-4 Sie sind unser Top-Betrieb des Monats {{ formatMonth(companyOfTheMonth) }} auf {{ vertical.name }}.
            p Mit dem Titel "Top-Betrieb des Monats" zeichnen wir die Betriebe aus, die durch ihre aktive Zusammenarbeit mit uns und den Endkunden überzeugen.
        img.ml-3(:src="companyOfTheMonthImage" height="150")

    h2.mb-5 Folgende Erfolgskriterien haben Sie erfüllt:

    .row.mb-5
        .col-md-4.text-center
            img.w-50.d-block.mb-4.mx-auto(src="@/assets/icons/cotm-profile.svg")
            | hohes Engagement im Profil
        .col-md-4.text-center
            img.w-50.d-block.mb-4.mx-auto(src="@/assets/icons/cotm-attraction.svg")
            | aktive Arbeit mit zugewiesenen Kundenaufträgen
        .col-md-4.text-center
            img.w-50.d-block.mb-4.mx-auto(src="@/assets/icons/cotm-ratings.svg")
            | positive Bewertungen

    p Sie haben jetzt die Möglichkeit dieses Siegel und somit die Werte Ihrer Auszeichnung auch auf Ihrer Unternehmenswebsite zu präsentieren. So sind  sie auch dort für Ihre potenziellen Endkunden und Besucher der Website sichtbar und vermitteln zusätzliches Vertrauen.

    p.mb-4 Um das Siegel direkt auf Ihrer Website einbinden zu können haben wir für Sie eine kurze Anleitung erstellt, mit der Sie Schritt für Schritt vorgehen können.

    textarea.form-control(disabled=true) {{ companyOfTheMonthHTML }}
    .text-center.mt-3
        button.btn.btn-secondary(@click="copyHTML") HTML kopieren

    .row.mt-5
        .col-md-3.text-center
            .number 1
            .text Kopieren Sie den oben gezeigten HTML Code
        .col-md-3.text-center
            .number 2
            .text Suchen Sie sich einen passenden Platz auf Ihrer Homepage aus, an dem Sie Ihr Qualitätssiegel präsentieren möchten*
        .col-md-3.text-center
            .number 3
            .text Fügen Sie den Code dort an der gewünschten Stelle ein.
        .col-md-3.text-center
            .number 4
            .text Speichern Sie Ihre Änderungen und fertig!

    p.small.mt-4 * Falls Sie Ihre Homepage durch jemanden betreuen lassen, senden Sie den HTML Code an den Verwalter Ihrer Homepage und geben einfach an, wo sie das Widget eingebunden haben möchten.

    p.mb-0 Sie haben Fragen zu Ihrem Siegel? Dann wenden Sie sich gerne an unsere Expertinnen aus dem Kundenservice Birgit Koll oder Diana Gehwald unter der 0911 3409 83283.

</template>

<script>
import { mapGetters } from 'vuex'
import { formatMonth } from '@/utils/formatters'

export default {
    computed: {
        ...mapGetters([
            'companyOfTheMonth',
            'profileLink',
            'secret',
            'vertical'
        ]),
        companyOfTheMonthImage () {
            return `${this.vertical.url}/img/illustrations/companyofthemonth-${this.companyOfTheMonth}.svg`
        },
        companyOfTheMonthHTML () {
            return `<a href="${this.profileLink}?utm_source=backlink&utm_medium=top-betrieb&utm_campaign=${this.companyOfTheMonth}"><img src="${this.companyOfTheMonthImage}"></a>`
        }
    },
    created () {
        if (!this.companyOfTheMonth) {
            this.$router.push(`/${this.secret}`)
        }
    },
    methods: {
        formatMonth,
        copyHTML () {
            const _input = document.createElement('input')
            _input.value = this.companyOfTheMonthHTML
            document.body.appendChild(_input)
            _input.select()
            document.execCommand('Copy')
            _input.remove()
        }
    }
}
</script>

<style lang="scss" scoped>
.number {
    font-size: 3rem;
    font-weight: bold;
}
</style>
