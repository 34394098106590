import { DateTime } from 'luxon'

export function formatCurrency (amount, currency) {
    currency = currency || 'EUR'
    return parseFloat(amount).toLocaleString('de-DE', {
        style: 'currency',
        currency
    })
}

export function formatMonth (iso8601) {
    return DateTime.fromISO(iso8601).setLocale('de').toFormat('MMMM yyyy')
}

export function formatDate (iso8601) {
    return DateTime.fromISO(iso8601).setLocale('de').toLocaleString(DateTime.DATE_MED)
}

export function formatDateTime (iso8601) {
    // .format('DD.MM.YYYY - HH:mm')} Uhr
    return DateTime.fromISO(iso8601).setLocale('de').toLocaleString(DateTime.DATETIME_MED)
}

export default {
    formatCurrency,
    formatDate,
    formatDateTime,
    formatMonth
}
