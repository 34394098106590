<template lang="pug">
main
    p.small: router-link(:to="`/${secret}/partner`") &laquo; Zurück zu den Partnerprogrammen
    h1.h2.my-4 Inkasso für Handwerker &amp; Dienstleister

    .row
        .col-md-8
            p Konzentrieren Sie sich auf das, was Ihnen wichtig ist und lassen Sie offene Rechnungen von Profis einziehen, die wissen, wo der Hammer hängt. Ohne Kostenrisiko. Einfach das beste Inkasso für Handwerker!

            .mb-2
                span.text-primary.mr-2 ✓
                | Bequeme Fallübergabe Online oder per App
            .mb-2
                span.text-primary.mr-2 ✓
                | Hohe Erfolgsquote bei Forderungen
            .mb-2
                span.text-primary.mr-2 ✓
                | Exklusive Sonderkonditionen für Premium Mitglieder
            .mb-2
                span.text-primary.mr-2 ✓
                | 100 € Guthaben für Bonitätsauskünfte geschenkt

        .col-md-4
            img.w-100.my-4(src="@/assets/logo-firstdebit.svg")

    .d-flex.align-items-top.my-4
        .font-weight-bold.mr-2 Unser Angebot:
        .price.mx-3 149 €
        s.price-old statt 249 €*

    .d-flex.align-items-center
        button.btn.btn-secondary.mr-4(@click="showModal = true") Kontakt
        a.font-weight-bold(href="https://www.youtube.com/watch?v=vsWRl23M6W4", rel="noopener", target="_blank") &#9658; Video abspielen

    //- Contact Form Modal
    .modal-backdrop.fade.show(v-if="showModal")
    .modal(:class="{ 'd-block': showModal }", tabindex="-1", role="dialog")
        .modal-dialog.modal-dialog-scrollable: .modal-content
            .modal-header
                strong.modal-title Ihre Kontaktanfrage
                button.close(type="button", @click="showModal = false")
                    span(aria-hidden="true") &times;
            .modal-body
                p.text-primary.font-weight-bold.mb-4
                    | Prüfen Sie bitte Ihre Kontaktdaten und ergänzen Sie diese gegebenenfalls.

                form(@submit.prevent="submit")
                    .form-group
                        label Firma
                        input.form-control(v-model="form.company")

                    .form-group
                        label Vorname, Nachname (Pflichtfeld)
                        input.form-control(v-model="form.name", required)

                    .form-group
                        label E-Mail-Adresse (Pflichtfeld)
                        input.form-control(v-model="form.email", required)

                    .form-group
                        label Telefonnummer
                        input.form-control(v-model="form.phone")

                    .form-group
                        label Ich bin interessiert an: (Pflichtfeld)
                        textarea.form-control(v-model="form.body", rows="6", required)

                    p.small Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an info@inkasso-fuer-handwerker.de widerrufen.

                    .form-group.mb-4
                        .form-check
                            input#accept_terms.form-check-input(type="checkbox", name="accept_terms", required)
                            label.form-check-label(for="accept_terms") Ich bin einverstanden (Pflichtfeld)

                    button.btn.btn-secondary(type="submit") Senden
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/utils/api'

export default {
    data () {
        return {
            form: {
                company: '',
                name: '',
                email: '',
                phone: '',
                body: ''
            },
            showModal: false
        }
    },
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ])
    },
    async created () {
        api.get('/profile').then(res => {
            this.form.company = res.data.name
            this.form.name = `${res.data.contact.firstname || ''} ${res.data.contact.lastname || ''}`.trim()
            this.form.email = res.data.contact.email
            this.form.phone = res.data.contact.phone || res.data.contact.mobile || ''
        })
    },
    methods: {
        async submit () {
            const { form } = this

            // Required Fields
            if (!form.name || !form.email || !form.body) {
                return
            }

            await api.post('/contact/firstdebit', form)

            this.success = true
            this.form.body = ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

figure.check {
    margin: 0;
    margin-right: 0.4rem;
    width: 1rem;
}

.price {
    color: $secondary;
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    line-height: 1;
}

.price-old {
    font-weight: $font-weight-bold;
}
</style>
