<template lang="pug">
main(v-if="availableProducts")
    p.small: router-link(:to="`/${secret}`") &laquo; Zurück zur Anfragenübersicht

    h1.h2.mb-5.text-center
        | Jetzt Paket wechseln
        br
        small &amp; mehr Anfragen erhalten

    .row.align-content-stretch.flex-wrap
        .col-sm-6.col-lg-3.mb-4(style="padding-top: 1.75rem")
            .card.card-body.h-100
                header.text-center
                    strong Free-Paket
                    hr
                    h2.text-center 0€
                    small &nbsp;
                    hr
                section.text-center
                    p.small #[strong Annahme von bis zu 2 Kundenanfragen] inkl. Freischaltung der Kundeninfo pro Jahr
                    .btn.btn-light.btn-block(v-if="isFree") Derzeitiges Paket
                    .btn(v-else) &nbsp;
                    hr
                section.small
                    p &bull; Unbegrenzt Kundenanfragen aus Ihrer Region per E-Mail
                    p &bull; Profil mit Kontaktdaten auf {{ vertical.name }}

        .col-sm-6.col-lg-3.mb-4(style="padding-top: 1.75rem")
            .card.card-body.h-100
                header.text-center
                    strong Business-Paket
                    hr
                    h2.text-center {{ availableProducts.business.price }}€
                    small pro Monat
                    hr
                section.text-center
                    p.small #[strong Annahme von bis zu 10 Kundenanfragen] inkl. Freischaltung der Kundeninfo pro Monat
                    .btn.btn-light.btn-block.btn-disabled(v-if="currentProduct === 'business'") Derzeitiges Paket
                    button.btn.btn-secondary.btn-block(v-else-if="isFree", @click="selectProduct('business')") Jetzt buchen
                    .btn(v-else) &nbsp;
                    hr
                section.small
                    p.font-weight-bold Alle Vorteile des Free-Pakets und:
                    p &bull; Unbegrenzt Kundenanfragen aus Ihrer Region wahlweise auch per SMS &amp; Fax
                    p &bull; Ausgewählte Kundenanfragen mit hohem Auftragswert
                    p &bull; Anzeige der Entfernung zum Auftragsort
                    p &bull; Individuell wählbarer Umkreis für Kundenanfragen 0-50 km
                    p &bull; Ausführliches Unternehmensprofil mit bis zu 3 Bildern, Logo und Spezialisierungen sowie Unternehmensvorstellung
                    p &bull; Bewertungsmanagement (5 Bewertungskarten zum Start inkl.)
                    p &bull; Werbefreies Profil
                    p &bull; Einbindung eines Videos

        .col-sm-6.col-lg-3.mb-4
            .card.border-primary.h-100
                .card-header.bg-primary.text-center.py-1
                    small.font-weight-bold.text-white.text-uppercase Unsere Empfehlung

                .card-body
                    header.text-center
                        strong Expert-Paket
                        hr
                        h2.text-center {{ availableProducts.expert.price }}€
                        small pro Monat
                        hr
                    section.text-center
                        p.small #[strong Annahme von bis zu 25 Kundenanfragen] inkl. Freischaltung der Kundeninfo pro Monat
                        button.btn.btn-light.btn-block(v-if="currentProduct === 'expert'") Derzeitiges Paket
                        button.btn.btn-secondary.btn-block(
                            v-else-if="isFree || currentProduct === 'business'",
                            @click="selectProduct('expert')"
                        ) Jetzt buchen
                        .btn(v-else) &nbsp;
                        hr
                    section.small
                        p.font-weight-bold Alle Vorteile des Business-Pakets und:
                        p &bull; Individuell wählbarer Umkreis für Kundenanfragen 0-100 km
                        p &bull; Ausführliches Unternehmensprofil mit bis zu 25 Bildern
                        p &bull; Bewertungsmanagement mit 50 Bewertungskarten pro Jahr inkl.

        .col-sm-6.col-lg-3.mb-4(style="padding-top: 1.75rem")
            .card.card-body.h-100
                header.text-center
                    strong Premium-Paket
                    hr
                    h2.text-center(style="font-size: 1.4rem;padding: 0.65rem;") Preis auf Anfrage
                    hr
                section.text-center
                    p.small #[strong Unbegrenzte Annahme von Kundenanfragen] inkl. Freischaltung der Kundeninfo
                    button.btn.btn-light.btn-block(v-if="currentProduct === 'premium'") Derzeitiges Paket
                    button.btn.btn-secondary.btn-block(v-else, @click="showContactModal = true") Preis anfragen
                    hr
                section.small
                    p.font-weight-bold Alle Vorteile des Expert-Pakets und:
                    p &bull; Bundesweite Kundenanfragen oder wählbarer Umkreis von 0-200km
                    p &bull; Einstellung zusätzlicher Standorte

    //- Contact Modal
    .modal-backdrop.fade.show(v-if="showContactModal")
    .modal(:class="{ 'd-block': showContactModal }", tabindex="-1", role="dialog")
        .modal-dialog.modal-dialog-centered: .modal-content
            .modal-header
                strong.modal-title Preis auf Anfrage
                button.close(type="button", @click="showContactModal = false")
                    span(aria-hidden="true") &times;
            .modal-body.text-center
                h4 Rufen Sie uns für weitere Informationen einfach an!
                a.btn.btn-lg.btn-secondary.mt-4(:href="`tel:${vertical.salesperson.phone.replace(/[ -]+/g, '')}`") {{ vertical.salesperson.phone }}
</template>

<script>
import api from '@/utils/api'
import { mapGetters } from 'vuex'
import { formatDateTime } from '@/utils/formatters'

export default {
    emits: [
        'input'
    ],
    data () {
        return {
            availableProducts: null,
            currentProduct: null,
            showContactModal: false
        }
    },
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ]),
        isFree () {
            return !this.currentProduct || this.currentProduct === 'aktionskunde'
        }
    },
    async created () {
        this.loadProducts()
    },
    methods: {
        formatDateTime,
        loadProducts () {
            api.get('/products').then(res => {
                this.availableProducts = res.data.availableProducts
                this.currentProduct = res.data.currentProduct
            })
        },
        selectProduct (product) {
            this.$emit('input', this.availableProducts[product])
        }
    }
}
</script>
