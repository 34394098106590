<template lang="pug">
.d-flex.align-items-center.flex-column.my-5(v-if="isLoading")
    .spinner-border.text-primary
    .mt-3 Lade Profildaten...

form(v-else, @submit.prevent="updateProfile")
    legend.my-4 Weitere Informationen zu Ihrem Unternehmen
    .row
        fieldset.col-md-6
            .form-row
                .form-group.col-6
                    label Gründungsjahr
                    input.form-control(placeholder="Gründungsjahr", v-model="profile.attributes.foundingYear")
                .form-group.col-6
                    label Anzahl Mitarbeiter
                    input.form-control(placeholder="Anzahl Mitarbeiter", v-model="profile.attributes.numEmployees")
            .form-group
                label Handelsregister
                input.form-control(placeholder="Handelsregister", v-model="profile.attributes.registerNumber")

        fieldset.col-md-6
            .form-group
                label.d-block Ist Ihr Betrieb ein Meisterbetrieb?
                .form-check.form-check-inline
                    input#isMeisterbetriebTrue.form-check-input(
                        type="radio",
                        v-model="profile.attributes.isMeisterbetrieb",
                        :value="true"
                    )
                    label.form-check-label(for="isMeisterbetriebTrue") Ja
                .form-check.form-check-inline
                    input#isMeisterbetriebFalse.form-check-input(
                        type="radio",
                        v-model="profile.attributes.isMeisterbetrieb",
                        :value="false"
                    )
                    label.form-check-label(for="isMeisterbetriebFalse") Nein
            .form-group
                label.d-block Ist Ihr Betrieb ein Ausbildungsbetrieb?
                .form-check.form-check-inline
                    input#isTrainingCompanyTrue.form-check-input(
                        type="radio",
                        v-model="profile.attributes.isTrainingCompany",
                        :value="true"
                    )
                    label.form-check-label(for="isTrainingCompanyTrue") Ja
                .form-check.form-check-inline
                    input#isTrainingCompanyFalse.form-check-input(
                        type="radio",
                        v-model="profile.attributes.isTrainingCompany",
                        :value="false"
                    )
                    label.form-check-label(for="isTrainingCompanyFalse") Nein

    .d-flex.align-items-center.justify-content-center.mt-5
        button.btn.btn-secondary.mr-4(type="submit") Änderungen speichern
        a.font-weight-bold(:href="profile.url", target="_blank") Profil aufrufen

    .text-center.text-success.mt-4(v-if="success") ✓ Änderungen erfolgreich gespeichert
</template>

<script>
import api from '@/utils/api'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            isLoading: true,
            success: false,
            profile: null
        }
    },
    computed: {
        ...mapGetters([
            'vertical'
        ])
    },
    async created () {
        this.profile = await api.get('/profile').then(res => res.data)
        this.isLoading = false
    },
    methods: {
        async updateProfile () {
            await api.post('/profile', this.profile)
            this.success = true
            // Reload Profile Data
            this.profile = await api.get('/profile').then(res => res.data)
        }
    }
}
</script>
