import axios from 'axios'
import store from '../store'

let isRefreshing = false

const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

client.interceptors.request.use(config => {
    if (store.getters.token) {
        config.headers.common.Authorization = `Bearer ${store.getters.token}`
    }

    return config
})

// If we get a 401 response, we try to re-login and try the request again
client.interceptors.response.use(
    ok => ok,
    async err => {
        if (!isRefreshing && err.response.status === 401) {
            // If we're not having a secret in our store, there's no chance we can refresh
            const secret = store.getters.secret
            if (!secret) {
                throw err
            }

            // Prevent recursive 401 requests
            isRefreshing = true

            // Obtain new JWT Token via Secret from Local Storage
            try {
                const login = await client.post('/token', { secret }).then(res => res.data)
                store.dispatch('login', login)
                store.dispatch('changeLoggedin', { loggedin: login.loggedin })
            } catch (jwtErr) {
                // Re-Login failed - throw original error
                throw err
            }

            // If successful, retry the original request with new token
            try {
                const retryConfig = err.config
                retryConfig.headers.Authorization = `Bearer ${store.getters.token}`
                const retry = await client(retryConfig)

                isRefreshing = false

                return retry
            } catch (retryErr) {
                isRefreshing = false

                // If the retry fails again - throw original error
                throw err
            }
        }

        throw err
    }
)

export default client
