<template lang="pug">
main
    p.small: router-link(to="/") &laquo; Zurück zur Übersicht
    h1.h2.my-4 {{ vertical.name }} Experte werden

    .row
        .col-md-6.mb-4
            .card.bg-light.card-body.p-5
                p Sie haben Lust, Ihr Fachwissen weiterzugeben? Werden Sie Experte auf {{ vertical.name }} und führen Sie zum Beispiel mit unserer Redaktion ein Experteninterview zu einem Thema aus Ihrem Fachbereich. Ihr Expertenartikel wird prominent in unserem Online-Fachmagazin veröffentlicht.
                p Selbstverständlich mit einem Link zu Ihrem Unternehmensprofil und Ihrer Homepage. Nach erfolgreicher Veröffentlichung schalten wir für Sie das “Experten-Banner” in Ihrem Profil frei.
                p Sie haben Interesse? Füllen Sie unser Kontaktformular aus und unser Redaktionsteam wird sich zeitnah mit Ihnen in Verbindung setzen.

                p.font-weight-bold Ihre Vorteile:

                p
                    | » Exklusives Interview oder Expertenzitat auf {{ vertical.name }}
                    br
                    | » Experten Banner auf Ihrem Unternehmensprofil
                    br
                    | » No-Follow Link zu Ihrem Profil und Ihrer Homepage

                figure.mt-4
                    img.img-fluid(src="@/assets/illustrations/interview.svg")

        .col-md-6
            .alert.alert-success.mb-5(v-if="success")
                strong Vielen Dank für Ihre Nachricht!
                br
                | Wir werden uns umgehend bei Ihnen melden.

            h3.mb-5 Wir freuen uns von Ihnen zu hören

            form(@submit.prevent="submit")
                .form-group.mb-5
                    textarea.form-control(rows="12", :placeholder="placeholder", v-model="body", required)

                .form-group.text-center
                    button.btn.btn-primary(type="submit") Absenden
n
</template>

<script>
import api from '@/utils/api'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            success: false,
            placeholder: 'Beschreiben Sie hier bitte kurz das Thema, über das Sie sich ein Interview vorstellen könnten...',
            subject: 'Experte werden',
            body: ''
        }
    },
    computed: {
        ...mapGetters([
            'vertical'
        ])
    },
    methods: {
        async submit () {
            const { subject, body } = this

            if (!subject || !body) {
                return
            }

            await api.post('/contact', { subject, body })

            this.success = true
            this.body = ''
        }
    }
}
</script>
