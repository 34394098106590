<template lang="pug">
router-link.partner-banner(:to="`/${secret}/partner/${id}`")
    .card.card-body.h-100: .row
        .col-6.col-lg-4: img.w-100(:src="img")
        .col-lg-8
            h3(:to="`/${secret}/partner/${id}`") {{ title }}
            p {{ body }}
</template>

<script>
import { mapGetters } from 'vuex'

const partners = {
    designenlassen: {
        img: require('@/assets/logo-designenlassen.png'),
        title: 'designenlassen.de',
        body: 'Logos, Flyer u.v.m.! Erhalten Sie in nur 7 Tagen eine Vielzahl an individuellen Vorschlägen von Profi-Designern und bezahlen Sie nur das Design, das Ihnen am besten gefällt.'
    },
    firstdebit: {
        img: require('@/assets/logo-firstdebit.svg'),
        title: 'First Debit',
        body: 'Konzentrieren Sie sich auf Ihr Tagesgeschäft und profitieren von unseren Sonderkonditionen bei First Debit - Inkasso für Handwerker und Dienstleister'
    },
    luftverbundrechner: {
        img: require('@/assets/logo-luftverbundrechner.svg'),
        title: 'Luftverbundrechner',
        body: 'Erstellen Sie mit dem Luftverbundrechner schnell, effizient und einfach Nachweise über den Verbrennungsluftverbund nach TRGI2018.'
    },
    meindachdecker: {
        img: require('@/assets/logo-meindachdecker.png'),
        title: 'MeinDachdecker',
        body: 'Chaos und Zettelwirtschaft war gestern: Mit der App von Handwerkern für Handwerker digitalisieren Sie die wichtigsten Betriebsprozesse.'
    },
    meinmaurer: {
        img: require('@/assets/logo-meinmaurer.png'),
        title: 'MeinMaurer',
        body: 'Chaos und Zettelwirtschaft war gestern: Mit der App von Handwerkern für Handwerker digitalisieren Sie die wichtigsten Betriebsprozesse.'
    },
    meinshk: {
        img: require('@/assets/logo-meinshk.png'),
        title: 'MeinSHK',
        body: 'Chaos und Zettelwirtschaft war gestern: Mit der App von Handwerkern für Handwerker digitalisieren Sie die wichtigsten Betriebsprozesse.'
    },
    meinzimmerer: {
        img: require('@/assets/logo-meinzimmerer.png'),
        title: 'MeinZimmerer',
        body: 'Chaos und Zettelwirtschaft war gestern: Mit der App von Handwerkern für Handwerker digitalisieren Sie die wichtigsten Betriebsprozesse.'
    }
}

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ]),
        img () {
            if (!partners[this.id]) {
                return
            }

            return partners[this.id].img
        },
        title () {
            if (!partners[this.id]) {
                return
            }

            return partners[this.id].title
        },
        body () {
            if (!partners[this.id]) {
                return
            }

            return partners[this.id].body
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.partner-banner {
    margin-bottom: 1rem;
    text-decoration: none;

    @include media-breakpoint-down(md) {
        img {
            margin-bottom: 1rem;
        }
    }

    &:hover {
        .card {
            border-color: $primary;
        }
    }

    .card-body {
        color: $body-color;

        p {
            margin-bottom: 0;
        }
    }
}
</style>
