<template lang="pug">
.card
    .card-header
        a.d-flex.align-items-center(href="#", @click.prevent="show = !show")
            img.mr-3(src="@/assets/icons/plusmark.svg")
            | {{ data.question }}
    .collapse(:class=`show && 'show'`)
        .card-body(v-html="data.answer")
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default () {
                return {
                    question: '',
                    answer: ''
                }
            }
        }
    },
    data () {
        return {
            show: false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

.card-header {
    background-color: $white;

    a {
        color: $body-color;
        font-weight: $font-weight-bold;
        text-decoration: none;
    }
}

.card-body {
    background-color: theme-color-level('primary', -11);
}
</style>
