<template lang="pug">
main
    p.small: router-link(to="/") &laquo; Zurück zur Übersicht
    h1.h2.my-4 Profil bearbeiten

    nav.d-flex.justify-content-between.my-4
        button.btn.w-25(:class="tabClass('data')", @click="gotoTab('data')") Firmen- &amp; Kontaktdaten
        button.btn.w-25(:class="tabClass('info')", @click="gotoTab('info')") Weitere Informationen
        button.btn.w-25(:class="tabClass('media')", @click="gotoTab('media')") Bildergalerie

    DataTab(v-if="currentTab === 'data'")
    InfoTab(v-if="currentTab === 'info'")
    MediaTab(v-if="currentTab === 'media'")

    upgrade-required-modal(:showModal="showModal", @closeUpgradeRequiredModal="showModal=false")
</template>

<script>
import api from '@/utils/api'
import DataTab from './_Data'
import InfoTab from './_Info'
import MediaTab from './_Media'
import UpgradeRequiredModal from '@/components/UpgradeRequiredModal.vue'

export default {
    components: {
        UpgradeRequiredModal,
        DataTab,
        InfoTab,
        MediaTab
    },
    data () {
        return {
            currentTab: 'data',
            showModal: false,
            profile: null
        }
    },
    computed: {
        canAccessMediaTab () {
            return !!this.profile.product
        }
    },
    async created () {
        this.profile = await api.get('/profile').then(res => res.data)
    },
    methods: {
        gotoTab (tabName) {
            if (tabName === 'media' && !this.canAccessMediaTab) {
                this.showModal = true
                return
            }

            this.currentTab = tabName
        },
        tabClass (tabName) {
            return this.currentTab === tabName ? 'btn-primary' : 'btn-outline-primary'
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

nav {
    @include media-breakpoint-down(md) {
        flex-direction: column;

        .btn {
            margin-bottom: 0.4rem;
            width: 100% !important;
        }
    }
}
</style>
