<template lang="pug">
main
    p.small: router-link(to="/") &laquo; Zurück zur Übersicht
    h1.h2.mb-4 Kontaktmöglichkeiten

    .row
        .col-md-6.mb-4
            .card.bg-light.card-body.p-5
                h3.mb-4 Kundenservice

                .d-flex
                    figure.mr-4: include ../assets/icons/phone.svg
                    p 0911 - 3409 83283

                .d-flex
                    figure.mr-4: include ../assets/icons/mail.svg
                    p info@digitaleseiten.de

                h3.my-4 Vertrieb

                .d-flex
                    figure.mr-4: include ../assets/icons/mail.svg
                    p sales@digitaleseiten.de

                figure.mt-4
                    img.img-fluid(src="@/assets/illustrations/contact.svg")

        .col-md-6
            .alert.alert-success.mb-5(v-if="success")
                strong Vielen Dank für Ihre Nachricht!
                br
                | Wir werden uns umgehend bei Ihnen melden.

            p.text-muted Sie haben Feedback, Fragen oder Anregungen?
            h3.mb-5 Wir freuen uns von Ihnen zu hören

            form(@submit.prevent="submit")
                .form-group
                    select.form-control(v-model="subject", required)
                        option(disabled, hidden, value="") Bitte wählen Sie Ihr Anliegen aus
                        option(value="Benutzerkonto") Benutzerkonto
                        option(value="Mitgliedschaft") Mitgliedschaft
                        option(value="Rechnung & Zahlung") Rechnung &amp; Zahlung
                        option(value="Feedback") Feedback
                        option(value="Sonstiges") Sonstiges

                .form-group
                    textarea.form-control(rows="12", v-model="body", required)

                .form-group.text-center
                    button.btn.btn-primary(type="submit") Absenden
</template>

<script>
import api from '@/utils/api'

export default {
    data () {
        return {
            success: false,
            subject: '',
            body: ''
        }
    },
    methods: {
        async submit () {
            const { subject, body } = this

            if (!subject || !body) {
                return
            }

            await api.post('/contact', { subject, body })

            this.success = true
            this.subject = ''
            this.body = ''
        }
    }
}
</script>

<style lang="scss" scoped>
svg path {
    fill: #ed6b1d;
}
</style>
