<template lang="pug">
main(v-if="isLoading")
    .d-flex.align-items-center.flex-column.my-5
        .spinner-border.text-primary
        .mt-3 Sie werden abgemeldet...

main(v-else)
    .text-center
        .success-icon.mx-auto.mb-4(style="width: 3rem")
            include ../../assets/icons/check.svg

        h1.h2.mb-4 Erfolgreich abgemeldet

        p Sie erhalten ab sofort keine weiteren Kundenanfragen von {{ vertical.name }}

    hr.my-5

    .row.align-content-stretch.flex-wrap
        .col-lg-4.mb-4
            .card.card-body.h-100
                h3 Mehr Anfragen annehmen?
                p Sie können mehr Anfragen annehmen, wenn Sie sich für eines unserer Pakete entscheiden.
                router-link(:to="`/${secret}/upgrade`") Hier gehts zur Paketübersicht &raquo;
        .col-lg-4.mb-4
            .card.card-body.h-100
                h3 Weniger Benachrichtigungen?
                p Sprechen Sie mit uns, wenn Sie weiterhin Kundenanfragen erhalten, aber nicht per E-Mail darüber benachrichtigt werden wollen.
        .col-lg-4.mb-4
            .card.card-body.h-100
                h3 Andere oder weitere Anfragen?
                p Wir können die Dienstleistungen für Ihr Profil so konfigurieren, dass Sie nur Anfragen erhalten, die für Sie von Interesse sind.

    .text-center
        p Um Änderungen vorzunehmen, wenden Sie sich gerne an unseren
        h4 Kundenservice in Nürnberg
        a.h2(href="tel:+4991198103245") 0911 - 981 03 245
</template>

<script>
import api from '@/utils/api'
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            isLoading: true
        }
    },
    computed: {
        ...mapGetters([
            'secret',
            'vertical'
        ])
    },
    async created () {
        await api.post('/profile/unsubscribe')
        this.isLoading = false
    }
}
</script>
