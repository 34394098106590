<template lang="pug">
template(v-for="star of 5")
    img(v-if="rating >= star", src="@/assets/icons/rating-full.svg")
    img(v-else-if="rating >= star - 0.5", src="@/assets/icons/rating-half.svg")
    img(v-else, src="@/assets/icons/rating-empty.svg")
</template>

<script>
export default {
    props: {
        rating: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

span {
    line-height: 16px;
}
</style>
