<template lang="pug">
main
    p.small: router-link(:to="`/${secret}/partner`") &laquo; Zurück zu den Partnerprogrammen
    h1.h2.my-4 Designen lassen von  designenlassen.de

    .row
        .col-md-8
            p
                | Bei designenlassen.de  erhalten Sie professionelle Designs aller Art:
                | egal ob Logo, Webdesign, Flyer, Fahrzeugbeschriftung, Einladungskarten und vieles mehr.
                | Sie bestimmen dabei den Preis, den Sie bezahlen wollen. Mithilfe des Wettbewerbsprinzips erhalten
                | Sie in nur 7 Tagen eine Vielzahl an individuellen Entwürfen und bezahlen am Ende nur das Design,
                | das Ihnen am besten gefällt.
            .mb-2
                span.text-primary.mr-2 ✓
                | Auswahl aus einer Vielzahl an individuellen Vorschlägen
            .mb-2
                span.text-primary.mr-2 ✓
                | Ihr Design in nur 7 Tagen
            .mb-2
                span.text-primary.mr-2 ✓
                | Sie bestimmen den Preis
            .mb-2
                span.text-primary.mr-2 ✓
                | Übertragung der Nutzungsrechte nach deutschem Recht

        .col-md-4
            img.w-100.my-4(src="@/assets/logo-designenlassen.png")

    .d-flex.align-items-top.my-4
        .font-weight-bold.mr-2 Unser Angebot:
        .price.mx-3 10% Rabatt* mit dem Code dsexklusiv

    .d-flex.align-items-center.mt-4
        a.btn.btn-secondary.mr-4(href="https://designenlassen.de/?utm_source=website&utm_medium=website&utm_campaign=digitaleseiten&utm_id=koop", target="_blank", rel="noopener") Hier starten!

    .mt-4.small * auf die Einstellgebühr - gültig bis 31.12.2023
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'secret'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/theme";

figure.check {
    margin: 0;
    margin-right: 0.4rem;
    width: 1rem;
}

.price {
    color: $secondary;
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    line-height: 1;
}

.price-old {
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
}
</style>
