<template lang="pug">
main(v-if="isLoading")
    .d-flex.align-items-center.flex-column.my-5
        .spinner-border.text-primary
        .mt-3 Schalte Zugangsdaten frei...

main.text-center(v-else-if="error")
    h1.h2.mb-4 Das hat leider nicht geklappt

    p
        | Dieser Link ist nicht mehr verfügbar oder bereits bestätigt.
        br
        | Versuchen Sie sich mit Ihren festgelegten Zugangsdaten einzuloggen.
        br
        br
        | Falls weiterhin Probleme auftauchen kontaktieren Sie bitte unseren Kundensupport.

main.text-center(v-else)
    .success-icon.mx-auto.mb-4(style="width: 3rem")
        include ../../assets/icons/check.svg

    h1.h2.mb-4 Vielen Dank!

    p.mb-5
        | Ihre Zugangsdaten sind bestätigt und nachfolgend können Sie sich in Ihrem Kundenbereich einloggen

    router-link.btn.btn-primary(to="/einloggen") Login
</template>

<script>
import api from '@/utils/api'

export default {
    data () {
        return {
            isLoading: true,
            error: false
        }
    },
    async created () {
        const { email, code } = this.$route.query

        if (!email || !code) {
            this.isLoading = false
            this.error = true
            return
        }

        try {
            await api.post('/profile/verification', { email, code })
        } catch {
            this.error = true
        }

        this.isLoading = false
    }
}
</script>
