<template lang="pug">
.container
    header.d-flex.justify-content-between.align-items-center
        router-link(:to="homepage")
            img(src="/img/logo.png")
        .d-flex
            router-link.d-none.d-md-block.btn.btn-secondary(v-if="secret", :to="`/${secret}/upgrade`") Jetzt Upgrade buchen!
            router-link.btn.btn-primary.ml-2(v-if="loggedin", to="/")
                .d-none.d-sm-block Übersicht
                .d-sm-none: img(src="@/assets/icons/user.svg", style="height: 2rem")
            router-link.btn.btn-primary.ml-2(v-else, to="/einloggen")
                .d-none.d-sm-block Login
                .d-sm-none: img(src="@/assets/icons/user.svg", style="height: 2rem")

    router-view

    footer.d-flex.flex-column.flex-md-row.justify-content-between.align-items-center
        span.d-none.d-lg-flex.align-items-center(v-if="vertical.salesperson")
            img.img-fluid.rounded-circle.mr-3(
                v-if="vertical.salesperson.image",
                :src="vertical.salesperson.image",
                style="width: 3rem"
            )
            .phone-icon(v-else, style="width: 3rem"): img(src="@/assets/icons/phone.svg")
            .text-left
                strong(v-if="vertical.salesperson.gender === 'm'") Ihr persönlicher Ansprechpartner
                strong(v-else-if="vertical.salesperson.gender === 'f'") Ihre persönliche Ansprechpartnerin
                br
                | {{ vertical.salesperson.name }} - {{ vertical.salesperson.phone }}
        ul.nav.small
            li.nav-item: a.nav-link(href="/agb", target="_blank") AGB
            li.nav-item: a.nav-link(href="/impressum", target="_blank") Impressum
            li.nav-item: a.nav-link(href="/datenschutz", target="_blank") Datenschutz
            li.nav-item: a.nav-link(@click="$store.dispatch('logout')") Ausloggen
            li.nav-item: router-link.nav-link(:to="`/${secret}/upgrade`") Upgrade
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'loggedin',
            'secret',
            'vertical'
        ]),
        homepage () {
            // If Profile is Loggedin - Home is the Dashboard
            if (this.loggedin) {
                return '/'
            }

            // Otherwise its the Assignments List
            return `/${this.secret}`
        }
    },
    created () {
        // set gtm variables of profile
        this.$router.afterEach((to, from) => {
            window.dataLayer.push({ profileLoggedin: !!this.loggedin })
        })
    }
}

</script>

<style lang="scss">
@import "@/assets/scss/theme";
@import "@/assets/scss/_vendor/bootstrap";

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

header {
    background-color: $white;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    padding: 1rem;

    img {
        margin: auto;
    }
}
@include media-breakpoint-up(sm) {
    header {
        padding: 2rem;

        img {
            margin: 0;
        }
    }
}

main {
    background-color: $white;
    padding: 1rem;
}
@include media-breakpoint-up(sm) {
    main {
        padding: 2rem;
    }
}

footer {
    background-color: $white;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    padding: 1rem;

    .nav-link {
        cursor: pointer;
    }
}
@include media-breakpoint-up(sm) {
    footer {
        padding: 2rem;
    }
}

[data-href] {
    cursor: pointer;
}

.success-icon {
    svg {
        fill: $success;
    }
}

.welcome-icon {
    width: 3rem;

    svg {
        fill: currentColor;
    }
}
</style>
