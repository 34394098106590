<template lang="pug">
main
    p.small: .breadcrumb-link(@click="moveToList") &laquo; Zurück zu den Bewertungen

    .answer-failed
        h3.mt-3.text-center Das hat leider nicht geklappt
        h6.text-center.mt-4 Ihre Antwort konnte leider nicht gesendet werden.
        h6.text-center Bitte gehen Sie zurück zu den Bewertungen und versuchen Sie es erneut.
        h6.text-center.mt-4 Falls weiterhin Probleme auftauchen kontaktieren Sie bitte unseren Kundensupport.

        .row.mt-4
            .col-6
                .row.justify-content-end.align-items-center.mr-1
                    include ../../../assets/icons/phone.svg
                    a.h6.ml-3.my-auto(href="tel:+49911340990299") +49 (0)911 3409 90299
            .col-6
                .row.align-items-center.ml-1
                    include ../../../assets/icons/mail.svg
                    a.h6.ml-3.my-auto(href="mailto:info@digitaleseiten.de") info@digitaleseiten.de

</template>

<script>
export default {
    emits: ['moveToList'],
    methods: {
        moveToList () {
            this.$emit('moveToList')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_breadcrumb";

.answer-failed {
    width: 70%;
    margin: auto;

    a {
        text-decoration: inherit;
        color: inherit;
        cursor: pointer;
    }

    svg path {
        fill: #ed6b1d;
    }
}

</style>
